// src/router.js
import { createBrowserRouter } from 'react-router-dom';
import Login from './components/Login';
import Dashboard from './screen/Dashboard';
import Layout from './components/Layout';
import Profile from './screen/Profile';
import PlayerManagement from './screen/PlayerManagement';
import AgentManagement from './screen/AgentManagement';
import SupportersManagement from './screen/SupportersManagement'
import ManagerManagement from './screen/ManagerManagement'
import ContentManagement from './screen/ContentManagement'
import TeamManagement from './screen/TeamManagement'
import PushNotifications from './screen/PushNotifications';
import Notification from './components/Notification';
import PlayerDetail from './screen/PlayerDetail';
import ProtectedRoute from './api/ProtectedRoute';
import AgentDetail from './screen/AgentDetail';
import SupporterDetail from './screen/SupporterDetail';
import ManagerDetail from './screen/ManagerDetail';
import ResultDetail from './screen/ResultDetail';

const router = createBrowserRouter([
    {
        path: "/",
        element: <Login />
    },
    {
        path: "/admin-dashboard",
        element: <Layout />,
        children: [
            {
                index: true,
                element: <ProtectedRoute element={<Dashboard />} />
            },
            {
                path: "admin-profile",
                element: <ProtectedRoute element={<Profile />} />
            },
            {
                path: "notification",
                element: <ProtectedRoute element={<Notification />} />
            },
            {
                path: "player-management",
                element: <ProtectedRoute element={<PlayerManagement />} />
            },
            {
                path: "player-detail",
                element: <ProtectedRoute element={<PlayerDetail />} />,
                children: [
                    {
                        path: "result-detail",
                        element: <ProtectedRoute element={<ResultDetail />} />
                    }
                ]
            },
            {
                path: "agent-management",
                element: <ProtectedRoute element={<AgentManagement />} />
            },
            {
                path: "agent-detail",
                element: <ProtectedRoute element={<AgentDetail />} />
            },
            {
                path: "supporters-management",
                element: <ProtectedRoute element={<SupportersManagement />} />
            },
            {
                path: "supporter-detail",
                element: <ProtectedRoute element={<SupporterDetail />} />
            },
            {
                path: "manager-management",
                element: <ProtectedRoute element={<ManagerManagement />} />
            },
            {
                path: "manager-detail",
                element: <ProtectedRoute element={<ManagerDetail />} />
            },
            {
                path: "content-management",
                element: <ProtectedRoute element={<ContentManagement />} />
            },
            {
                path: "team-management",
                element: <ProtectedRoute element={<TeamManagement />} />
            },
            {
                path: "push-notifications",
                element: <ProtectedRoute element={<PushNotifications />} />
            },

        ]
    }

]);

export default router;
