import React, { useState, useEffect } from 'react';
import { useProfileNameContext } from '../context/ProfileNameContext';
import { useLocation, useNavigate } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import { formatDate } from '../api/FormatDate';
import Select from 'react-select';
import CustomPieChart from '../common/PieChart';
import CustomPieChartTwo from '../common/AttackPieChartOne';
import CustomPieChartThree from '../common/AttackPieChartTwo';
import CustomPieChartFour from '../common/AttackPieChartThree';
import CustomPieChartFive from '../common/AttackPieChartFour';

const AgentDetail = () => {

    const [agent, setAgent] = useState(null);
    const [players, setPlayers] = useState(null);
    const [selectedPlayer, setSelectedPlayer] = useState(null);
    const [playerDetails, setPlayerDetails] = useState(null);
    const [selectedTeam, setSelectedTeam] = useState('');
    const [selectedSeason, setSelectedSeason] = useState('');
    const [selectedGameType, setSelectedGameType] = useState('');


    const [showDetail, setShowDetail] = useState(false);
    const [selectedDetail, setSelectedDetail] = useState(null);

    const handleCardClick = async (gameId, playerId) => {
        try {
            const token = localStorage.getItem('authToken');
            const url = new URL(`${REACT_APP_BACKEND_URL}${process.env.REACT_APP_AGENT_PLAYER_RESULT_DETAIL}`);
            url.searchParams.set('gameId', gameId);
            url.searchParams.set('playerId', playerId);
            const response = await axiosInstance.get(url.toString(), {
                headers: {
                    Authorization: `Bearer ${token}`
                }
            });
            console.log(response, "result detail");
            setSelectedDetail(response.data.data);
            setShowDetail(true);
        } catch (error) {
            console.error('Error fetching game details:', error);
        }
    };
    // Handle back to list view
    const handleBackClick = () => {
        setShowDetail(false);
        setSelectedDetail(null);
    };


    const location = useLocation();
    const navigate = useNavigate();
    const { setName } = useProfileNameContext();
    const queryParams = new URLSearchParams(location.search);

    const agentId = queryParams.get('id');

    const teamImage = (url) => {
        return `${process.env.REACT_APP_BACKEND_URL}/public/${url}`
    };

    useEffect(() => {
        const fetchAgent = async () => {

            try {
                const token = localStorage.getItem('authToken');
                const response = await axiosInstance.get(`${process.env.REACT_APP_AGENT_DETAILS_ENDPOINT}?id=${agentId}`, {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response.data.data, "agent detail")
                setAgent(response.data.data.agentDetails);
                setName(response.data.data.agentDetails.username);

                const playerData = response.data.data.players || [];

                const formattedPlayers = playerData.map(player => ({
                    value: player._id,
                    label: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={player?.profileImage ? player?.profileImage : '/assets/images/dummy-user.png'} alt={player.name} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                            {player.name}
                        </div>
                    )
                }));

                setPlayers([{ value: '', label: 'Select Player' }, ...formattedPlayers])
            } catch (error) {
                console.error('Failed to fetch agent details');
            }
        };

        if (agentId) {
            fetchAgent();
        } else {
            console.error('No agent ID provided');
        }
    }, [agentId]);


    const handleTeamChange = (e) => {
        setSelectedTeam(e.target.value);

    };

    const handleSeasonChange = (e) => {
        setSelectedSeason(e.target.value);

    };

    const handleGameTypeChange = (e) => {
        setSelectedGameType(e.target.value);

    };

    useEffect(() => {
        const fetchPlayerDetails = async () => {
            if (selectedPlayer && selectedPlayer.value) {
                try {
                    const token = localStorage.getItem('authToken');
                    const url = new URL(`${process.env.REACT_APP_AGENT_DETAILS_ENDPOINT}?id=${agentId}&playerId=${selectedPlayer.value}`);
                    if (selectedTeam) url.searchParams.set('team', selectedTeam);
                    if (selectedSeason) url.searchParams.set('season', selectedSeason);
                    if (selectedGameType) url.searchParams.set('type', selectedGameType);
                    const response = await axiosInstance.get(url.toString(), {
                        headers: {
                            Authorization: `Bearer ${token}`
                        }
                    });
                    console.log(response.data.data, "player details");
                    setPlayerDetails(response.data.data);
                } catch (err) {
                    console.error('Failed to fetch player details');
                }
            } else {

                setPlayerDetails(null);
            }
        };

        fetchPlayerDetails();
    }, [selectedPlayer, selectedTeam, selectedSeason, selectedGameType, agentId]);

    const handleChange = (selectedOption) => {
        setSelectedPlayer(selectedOption);
        navigate(`?id=${agentId}&playerId=${selectedOption.value}`);
    };

    return (

        <>
            {
                showDetail ? (<div>

                    <div className="content-wrapper">

                        <section className="main-section coach-section spacer">
                            <div className="container">
                                <div className="wrapper-card">
                                    <div className='back-button' onClick={handleBackClick}>

                                        <span><img src="/assets/images/logout-icon.svg" />Back to Stats</span>

                                    </div>
                                    <div className="row gy-5">
                                        <div className="col-xl-6">
                                            <div className="team-statistics">
                                                <div className="row g-3">
                                                    <div className="col-12">
                                                        {selectedDetail.teams.length > 0 && (() => {
                                                            const team1 = selectedDetail.teams.find(t => t.type === 'Self');
                                                            const team2 = selectedDetail.teams.find(t => t.type === 'Opponent');

                                                            // Ensure team1 and team2 are valid before proceeding
                                                            if (team1 && team2) {
                                                                const leftTeam = team1.score > team2.score ? team1 : team2;
                                                                const rightTeam = team1.score > team2.score ? team2 : team1;

                                                                return (
                                                                    <div className="team-result-card" key={team1.id}> {/* Assuming team1.id is unique */}
                                                                        <div className="team-left-card">
                                                                            <div className="team-img">
                                                                                <img src={leftTeam?.badge ? teamImage(leftTeam?.badge) : '/assets/images/manchester-logo.svg'} alt={leftTeam?.name} />
                                                                            </div>
                                                                            <h3>{leftTeam?.name}</h3>
                                                                        </div>
                                                                        <div className="result-stat">
                                                                            <p className="result-text">
                                                                                <span className="score lead-score">{leftTeam?.score}</span> ‐
                                                                                <span className="score trail-score">{rightTeam?.score}</span>
                                                                            </p>
                                                                        </div>
                                                                        <div className="team-right-card">
                                                                            <div className="team-img">
                                                                                <img src={rightTeam?.badge ? teamImage(rightTeam?.badge) : '/assets/images/liverpool-logo.svg'} alt={rightTeam?.name} />
                                                                            </div>
                                                                            <h3>{rightTeam?.name}</h3>
                                                                        </div>
                                                                    </div>
                                                                );
                                                            }

                                                        })()}

                                                    </div>



                                                    <div className="col-12 col-sm-6">
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Total Minutes Played</h3>
                                                                <h4>{selectedDetail?.timePlayed}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12 col-sm-6">
                                                        <div className="statistics-card">
                                                            <div className="statistics-info">
                                                                <h3>Total Goal Assists</h3>
                                                                <h4>{selectedDetail?.attack?.assist}</h4>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-12">
                                                        <div className="card-flex-content">
                                                            <h2>Bookings</h2>
                                                            <div className="statistics-card">
                                                                {selectedDetail.bookings.map((cards, index) => {
                                                                    return (
                                                                        <div className="statistics-info" key={index}>

                                                                            <h3>{cards?.cardName}</h3>
                                                                            <div className="match-card">
                                                                                <div className={`card-type ${cards.cardName === 'Yellow Cards' ? 'yellow-card' : 'red-card'}`} />
                                                                                <div className="match-card-count">{cards?.count}</div>
                                                                            </div>
                                                                        </div>
                                                                    )
                                                                })}


                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>

                                        <div className="col-md-6 col-lg-6">
                                            <div className="supporter-statistics bg-white">
                                                <div className="common-info-detail">
                                                    <h2 className="h4">Game type</h2>
                                                    <p className="mb-0">{selectedDetail?.gameType}</p>
                                                </div>
                                                <div className="common-info-detail">
                                                    <h2 className="h4">Stage</h2>
                                                    <p className="mb-0">{selectedDetail?.stage}</p>
                                                </div>
                                                <div className="common-info-detail">
                                                    <h2 className="h4">Match result</h2>
                                                    <p className="mb-0">{selectedDetail?.matchResult}</p>
                                                </div>
                                                <div className="common-info-detail">
                                                    <h2 className="h4">Captain</h2>
                                                    <p className="mb-0">{selectedDetail?.isCaptain === true ? 'Yes' : 'No'}</p>
                                                </div>
                                                <div className="common-info-detail">
                                                    <h2 className="h4">Man of the match</h2>
                                                    <p className="mb-0">{selectedDetail?.playerOfTheMatch === true ? 'Yes' : 'No'}</p>
                                                </div>
                                                <div className="common-info-detail">
                                                    <h2 className="h4">Position</h2>
                                                    <p className="mb-0">{selectedDetail?.position}</p>
                                                </div>
                                                <div className="common-info-detail">
                                                    <h2 className="h4">Match location</h2>
                                                    <p className="mb-0">{selectedDetail?.location}</p>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className="wrapper-card">
                                    <div className="row gy-5">
                                        <div className="col-xl-12">
                                            <div className="team-statistics">
                                                <div className="row g-3">
                                                    <div className='col-12'>
                                                        <div className='match_notes_blk'>
                                                            <h3 className='h4 fw-bold'>Match Notes</h3>
                                                            <p>{selectedDetail?.notes}</p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                <div className='commontab_wrapper'>
                                    <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">

                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link active" id="pills-attack-tab" data-bs-toggle="pill" data-bs-target="#pills-attack1" type="button" role="tab" aria-controls="pills-attack" aria-selected="true">Attack</button>
                                        </li>
                                        <li className="nav-item" role="presentation">
                                            <button className="nav-link" id="pills-defense-tab" data-bs-toggle="pill" data-bs-target="#pills-defense1" type="button" role="tab" aria-controls="pills-defense" aria-selected="false">Defense</button>
                                        </li>
                                    </ul>
                                    <div className="tab-content" >
                                        <div className="tab-pane fade show active" id="pills-attack1" role="tabpanel" aria-labelledby="pills-attack-tab" tabIndex={0}>
                                            <div className="wrapper-card">

                                                <div className="row g-3">
                                                    <div className="col-xl-6">
                                                        <div className="team-statistics">
                                                            <div className="row g-3">
                                                                <div className='col-12'>
                                                                    <div className='inner_wrapper_card new-inner_layer position-relative'>
                                                                        <div className='row'>
                                                                            <div className='col-sm-6 mt-3'>
                                                                                <div className='common_style_layout attack-assist-layout'>
                                                                                    <div className='common_style_item'>
                                                                                        <h3>Goals</h3>
                                                                                        <div className='common_style_value'>
                                                                                            <p>{selectedDetail?.attack?.goals}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex justify-content-between align-items-center gap-3 mt-2 px-2'>
                                                                                    <h3 className='mb-0 fs-6'>1st Half</h3>
                                                                                    <h3 className='mb-0 fs-6'>{selectedDetail?.attack?.firstHalf}</h3>
                                                                                </div>
                                                                            </div>
                                                                            <div className='col-sm-6 mt-3'>
                                                                                <div className='common_style_layout attack-assist-layout'>
                                                                                    <div className='common_style_item'>
                                                                                        <h3>Assists</h3>
                                                                                        <div className='common_style_value'>
                                                                                            <p>{selectedDetail?.attack?.assist}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='d-flex justify-content-between align-items-center gap-3 mt-2 px-2'>
                                                                                    <h3 className='mb-0 fs-6'>2nd Half</h3>
                                                                                    <h3 className='mb-0 fs-6'>{selectedDetail?.attack?.secondHalf}</h3>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="team-statistics">
                                                            <div className="bg-white-statistics">
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="top-heading d-flex justify-content-between mb-2">
                                                                            <h2>How Scored</h2>
                                                                            {/* <h3>{player?.attack?.howScoredtotalGoalsScored}</h3> */}
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-5'>
                                                                        <div className='row'>
                                                                            <div className="col-sm-12">
                                                                                <div className="how-score-blk">
                                                                                    <div className="how-score-icon">
                                                                                        <img src='/assets/images/right-foot.png' alt="Foot" />
                                                                                    </div>
                                                                                    <div className="how-score-info">
                                                                                        <h3>{selectedDetail?.attack?.howScored?.rightFoot}</h3>
                                                                                        <p>Right foot</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="how-score-blk">
                                                                                    <div className="how-score-icon">
                                                                                        <img src='/assets/images/left-foot.png' alt="Foot" />
                                                                                    </div>
                                                                                    <div className="how-score-info">
                                                                                        <h3>{selectedDetail?.attack?.howScored?.leftFoot}</h3>
                                                                                        <p>Left foot</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="how-score-blk">
                                                                                    <div className="how-score-icon">
                                                                                        <img src='/assets/images/head.png' alt="Head" />
                                                                                    </div>
                                                                                    <div className="how-score-info">
                                                                                        <h3>{selectedDetail?.attack?.howScored?.headed}</h3>
                                                                                        <p>Head</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-sm-12">
                                                                                <div className="how-score-blk border-bottom-0">
                                                                                    <div className="how-score-icon">
                                                                                        <img src='/assets/images/other.png' alt="By Other" />
                                                                                    </div>
                                                                                    <div className="how-score-info">
                                                                                        <h3>{selectedDetail?.attack?.howScored?.other}</h3>
                                                                                        <p>Other</p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className='col-lg-7'>
                                                                        <div className='how_pie_graph_blk'>
                                                                            <CustomPieChartTwo right={selectedDetail?.attack?.howScored?.rightFootPercentage || 0} left={selectedDetail?.attack?.howScored?.leftFootPercentage || 0} headed={selectedDetail?.attack?.howScored?.headedPercentage || 0} other={selectedDetail?.attack?.howScored?.otherPercentage || 0} />
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="team-statistics">
                                                            <div className="row g-3">
                                                                <div className='col-12'>
                                                                    <div className='inner_wrapper_card'>
                                                                        <div className="row g-3 align-items-center">
                                                                            <div className="col-12">
                                                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                                                    <h2>Where Scored</h2>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-12'>
                                                                                <div className='row'>
                                                                                    <div className='col-sm-6 mt-3'>
                                                                                        <div className='common_style_layout attack-assist-layout'>
                                                                                            <div className='common_style_item'>
                                                                                                <h3>Inside the box</h3>
                                                                                                <div className='common_style_value'>
                                                                                                    <p>{selectedDetail?.attack?.whereScored?.insideTheBox}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-sm-6 mt-3'>
                                                                                        <div className='common_style_layout attack-assist-layout'>
                                                                                            <div className='common_style_item'>
                                                                                                <h3>Outside the box</h3>
                                                                                                <div className='common_style_value'>
                                                                                                    <p>{selectedDetail?.attack?.whereScored?.outsideTheBox}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="team-statistics">
                                                            <div className="bg-white-statistics">
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="top-heading d-flex justify-content-between mb-2">
                                                                            <h2>Play When Scored</h2>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='col-lg-6'>
                                                                                    <div className='how_pie_graph_blk'>
                                                                                        <CustomPieChartFour openPlay={player?.attack?.playWhenScored?.openPlayPercentage || 0} penalty={player?.attack?.playWhenScored?.penaltyPercentage || 0} direct={player?.attack?.playWhenScored?.directFreeKickPercentage || 0} indirect={player?.attack?.playWhenScored?.indirectFreeKickPercentage || 0} corner={player?.attack?.playWhenScored?.cornerPercentage || 0} />
                                                                                    </div>
                                                                                </div> */}
                                                                    <div className='col-lg-12'>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#344BFD" }}>
                                                                                    </span>
                                                                                    <h3>Open Play</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.attack?.playWhenScored?.openPlay}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#FD3D59" }}>
                                                                                    </span>
                                                                                    <h3>Penalty</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.attack?.playWhenScored?.penalty}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#000000" }}>
                                                                                    </span>
                                                                                    <h3>Direct Free Kick</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.attack?.playWhenScored?.directFreeKick}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#EFEFEF" }}>
                                                                                    </span>
                                                                                    <h3>Indirect Free Kick</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.attack?.playWhenScored?.indirectFreeKick}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#E2E2E2" }}>
                                                                                    </span>
                                                                                    <h3>Corner</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.attack?.playWhenScored?.corner}</p>
                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wrapper-card">
                                                <div className="row g-3">
                                                    <div className="col-xl-8">
                                                        <div className="common-sub-heading">
                                                            <h2>Match images and videos</h2>
                                                        </div>
                                                        <div className="video-img-blk">
                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                <h2 className="fs-6 fw-medium">Videos</h2>
                                                                <a href="javascript:void(0)" className="fs-6 fw-medium text-black">View All</a>
                                                            </div>
                                                            <div className="match-card-blk">
                                                                {selectedDetail?.gallery?.videos.length > 0 && (
                                                                    selectedDetail?.gallery?.videos.map((video, index) => (
                                                                        <div className="match-card-item" key={index}>
                                                                            <div className="match-card-preview">
                                                                                {/* Display the video thumbnail */}
                                                                                <video width="100%" height="auto" controls>
                                                                                    <source src={teamImage(video.name)} type="video/mp4" />
                                                                                </video>
                                                                            </div>
                                                                            <h3 className="text-truncate">
                                                                                {new Date(video.createdAt).toLocaleDateString('en-GB', {
                                                                                    day: 'numeric',
                                                                                    month: 'long',
                                                                                    year: 'numeric',
                                                                                })}
                                                                            </h3>
                                                                        </div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="video-img-blk">
                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                <h2 className="fs-6 fw-medium">Images</h2>
                                                                <a href="javascript:void(0)" className="fs-6 fw-medium text-black">View All</a>
                                                            </div>
                                                            <div className="match-card-blk">
                                                                {selectedDetail?.gallery.images.length > 0 && (
                                                                    selectedDetail?.gallery?.images.map((image, index) => (
                                                                        <div className="match-card-item" key={index}>
                                                                            <div className="match-card-preview">
                                                                                <img src={teamImage(image.name)} alt="Gallery" />
                                                                            </div>
                                                                            <h3 className="text-truncate">
                                                                                {new Date(image.createdAt).toLocaleDateString('en-GB', {
                                                                                    day: 'numeric',
                                                                                    month: 'long',
                                                                                    year: 'numeric',
                                                                                })}
                                                                            </h3>
                                                                        </div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                        <div className="tab-pane " id="pills-defense1" role="tabpanel" aria-labelledby="pills-attack-tab" tabIndex={0}>
                                            <div className="wrapper-card">

                                                <div className="row g-3">
                                                    <div className='col-xl-6'>
                                                        <div className="team-statistics">
                                                            <div className="bg-white-statistics">
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="top-heading d-flex justify-content-between mb-2">
                                                                            <h2>Defence</h2>
                                                                        </div>
                                                                    </div>

                                                                    <div className='col-lg-12'>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">

                                                                                    <h3>Penalty Saves</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.penaltySaves}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">

                                                                                    <h3>Goals Conceded</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.goals}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">

                                                                                    <h3>1st Half</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.firstHalf}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">

                                                                                    <h3>2nd Half</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.secondHalf}</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="team-statistics">
                                                            <div className="row g-3">
                                                                <div className='col-12'>
                                                                    <div className='inner_wrapper_card'>
                                                                        <div className="row g-3 align-items-center">
                                                                            <div className="col-12">
                                                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                                                    <h2>Where Conceded</h2>
                                                                                </div>
                                                                            </div>

                                                                            <div className='col-12'>
                                                                                <div className='row'>
                                                                                    <div className='col-sm-6 mt-3'>
                                                                                        <div className='common_style_layout attack-assist-layout'>
                                                                                            <div className='common_style_item'>
                                                                                                <h3>Inside the box</h3>
                                                                                                <div className='common_style_value'>
                                                                                                    <p>{selectedDetail?.defence?.whereConceded?.insideTheBox}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className='col-sm-6 mt-3'>
                                                                                        <div className='common_style_layout attack-assist-layout'>
                                                                                            <div className='common_style_item'>
                                                                                                <h3>Outside the box</h3>
                                                                                                <div className='common_style_value'>
                                                                                                    <p>{selectedDetail?.defence?.whereConceded?.outsideTheBox}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                    <div className="col-xl-6">
                                                        <div className="team-statistics">
                                                            <div className="bg-white-statistics">
                                                                <div className="row g-3 align-items-center">
                                                                    <div className="col-12">
                                                                        <div className="top-heading d-flex justify-content-between mb-2">
                                                                            <h2>Player When Conceded</h2>
                                                                        </div>
                                                                    </div>
                                                                    {/* <div className='col-lg-6'>
                                                                        <div className='how_pie_graph_blk'>
                                                                            <CustomPieChartFour openPlay={player?.attack?.playWhenScored?.openPlayPercentage || 0} penalty={player?.attack?.playWhenScored?.penaltyPercentage || 0} direct={player?.attack?.playWhenScored?.directFreeKickPercentage || 0} indirect={player?.attack?.playWhenScored?.indirectFreeKickPercentage || 0} corner={player?.attack?.playWhenScored?.cornerPercentage || 0} />
                                                                        </div>
                                                                    </div> */}
                                                                    <div className='col-lg-12'>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#344BFD" }}>
                                                                                    </span>
                                                                                    <h3>Open Play</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.playWhenConceded?.openPlay}</p>
                                                                            </div>
                                                                        </div>

                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#FD3D59" }}>
                                                                                    </span>
                                                                                    <h3>Penalty</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.playWhenConceded?.penalty}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#000000" }}>
                                                                                    </span>
                                                                                    <h3>Direct Free Kick</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.playWhenConceded?.directFreeKick}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#EFEFEF" }}>
                                                                                    </span>
                                                                                    <h3>Indirect Free Kick</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.playWhenConceded?.indirectFreeKick}</p>
                                                                            </div>
                                                                        </div>
                                                                        <div className='conceded-score-graph-detail'>
                                                                            <div className="conceded-score-blk">
                                                                                <div className="conceded-score-info">
                                                                                    <span className="conceded-label-icon" style={{ backgroundColor: "#E2E2E2" }}>
                                                                                    </span>
                                                                                    <h3>Corner</h3>
                                                                                </div>
                                                                                <p>{selectedDetail?.defence?.playWhenConceded?.corner}</p>
                                                                            </div>

                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="wrapper-card">
                                                <div className="row g-3">
                                                    <div className="col-xl-8">
                                                        <div className="common-sub-heading">
                                                            <h2>Match images and videos</h2>
                                                        </div>
                                                        <div className="video-img-blk">
                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                <h2 className="fs-6 fw-medium">Videos</h2>
                                                                <a href="javascript:void(0)" className="fs-6 fw-medium text-black">View All</a>
                                                            </div>
                                                            <div className="match-card-blk">
                                                                {selectedDetail?.gallery?.videos.length > 0 && (
                                                                    selectedDetail?.gallery?.videos.map((video, index) => (
                                                                        <div className="match-card-item" key={index}>
                                                                            <div className="match-card-preview">
                                                                                {/* Display the video thumbnail */}
                                                                                <video width="100%" height="auto" controls>
                                                                                    <source src={teamImage(video.name)} type="video/mp4" />
                                                                                </video>
                                                                            </div>
                                                                            <h3 className="text-truncate">
                                                                                {new Date(video.createdAt).toLocaleDateString('en-GB', {
                                                                                    day: 'numeric',
                                                                                    month: 'long',
                                                                                    year: 'numeric',
                                                                                })}
                                                                            </h3>
                                                                        </div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </div>
                                                        <div className="video-img-blk">
                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                <h2 className="fs-6 fw-medium">Images</h2>
                                                                <a href="javascript:void(0)" className="fs-6 fw-medium text-black">View All</a>
                                                            </div>
                                                            <div className="match-card-blk">
                                                                {selectedDetail?.gallery.images.length > 0 && (
                                                                    selectedDetail?.gallery?.images.map((image, index) => (
                                                                        <div className="match-card-item" key={index}>
                                                                            <div className="match-card-preview">
                                                                                <img src={teamImage(image.name)} alt="Gallery" />
                                                                            </div>
                                                                            <h3 className="text-truncate">
                                                                                {new Date(image.createdAt).toLocaleDateString('en-GB', {
                                                                                    day: 'numeric',
                                                                                    month: 'long',
                                                                                    year: 'numeric',
                                                                                })}
                                                                            </h3>
                                                                        </div>
                                                                    ))
                                                                )}
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>


                            </div>
                        </section>
                    </div >
                </div >
                )
                    :
                    (
                        <div className="content-wrapper">
                            <section className="main-section coach-section spacer">
                                <div className="container">
                                    <div className="wrapper-card">
                                        <div className="row gx-lg-5 gy-3">
                                            <div className="col-xl-12">
                                                <div className="common-sub-heading">
                                                    <h2>{`${agent?.username} Details`}</h2>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <div className="supporter-statistics bg-white p-0">
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">User name</h2>
                                                        <p className="mb-0">{agent?.username}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Mobile number</h2>
                                                        <p className="mb-0">{agent?.phoneNumber}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">DOB</h2>
                                                        <p className="mb-0">{agent?.dob ? formatDate(agent?.dob) : ''}</p>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-md-6 col-lg-4">
                                                <div className="supporter-statistics bg-white p-0">
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Email address</h2>
                                                        <p className="mb-0">{agent?.email}</p>
                                                    </div>
                                                    <div className="common-info-detail">
                                                        <h2 className="h4">Gender</h2>
                                                        <p className="mb-0">{agent?.gender}</p>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className='select-player-blk'>
                                        <Select
                                            value={selectedPlayer}
                                            onChange={handleChange}
                                            options={players}
                                            isSearchable={false}
                                            placeholder="Select a player"
                                        />
                                    </div>
                                    {playerDetails && (
                                        <div className='player-detail' >
                                            <div className="content-wrapper">
                                                <section className="main-section coach-section spacer">
                                                    <div className="container">
                                                        <div className="team-stats-wrapper-card">
                                                            <div className="row g-3">
                                                                <div className="col-xl-12">
                                                                    <div className="select-team-statistics">
                                                                        <div className="form-block">
                                                                            <select className="form-select" onChange={handleTeamChange}>
                                                                                <option value="">Stats of Teams</option>
                                                                                {playerDetails?.teams?.map((team, index) => (
                                                                                    <option key={index} value={team?.name}>
                                                                                        {team?.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-block">
                                                                            <select className="form-select" onChange={handleSeasonChange}>
                                                                                <option value="">Stats of Seasons</option>
                                                                                {playerDetails?.seasons?.map((season, index) => (
                                                                                    <option key={index} value={season?._id}>
                                                                                        {season?.name}
                                                                                    </option>
                                                                                ))}
                                                                            </select>
                                                                        </div>
                                                                        <div className="form-block">
                                                                            <select className="form-select" onChange={handleGameTypeChange}>
                                                                                <option value="">Stats of Game Type</option>
                                                                                <option value="League">League</option>
                                                                                <option value="Cup/Tournament">Cup/Tournament</option>
                                                                                <option value="Friendly">Friendly</option>
                                                                            </select>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <div className='commontab_wrapper'>
                                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                                <li className="nav-item" role="presentation">
                                                                    <button className="nav-link active" id="pills-overall-tab" data-bs-toggle="pill" data-bs-target="#pills-overall" type="button" role="tab" aria-controls="pills-overall" aria-selected="true">Overall</button>
                                                                </li>
                                                                <li className="nav-item" role="presentation">
                                                                    <button className="nav-link" id="pills-attack-tab" data-bs-toggle="pill" data-bs-target="#pills-attack" type="button" role="tab" aria-controls="pills-attack" aria-selected="false">Attack</button>
                                                                </li>
                                                                <li className="nav-item" role="presentation">
                                                                    <button className="nav-link" id="pills-defense-tab" data-bs-toggle="pill" data-bs-target="#pills-defense" type="button" role="tab" aria-controls="pills-defense" aria-selected="false">Defense</button>
                                                                </li>
                                                            </ul>
                                                            <div className="tab-content" id="pills-tabContent">
                                                                <div className="tab-pane fade show active" id="pills-overall" role="tabpanel" aria-labelledby="pills-overall-tab" tabIndex={0}>
                                                                    <div className="wrapper-card">
                                                                        <div class="common-sub-heading">
                                                                            <h2>Match List</h2>
                                                                        </div>
                                                                        <div className="row g-3">
                                                                            <div className="col-xl-6">

                                                                                <div className="team-statistics">
                                                                                    <div className="row g-3">

                                                                                        <div className='col-12'>


                                                                                            <div className="team-result-card-list">
                                                                                                {
                                                                                                    playerDetails?.listWithTeams?.length > 0 && playerDetails.listWithTeams.map((teamsArray, index) => {
                                                                                                        // Find team1 and team2 in each teamsArray
                                                                                                        const team1 = teamsArray.teams.find(t => t.type === 'Self');
                                                                                                        const team2 = teamsArray.teams.find(t => t.type === 'Opponent');

                                                                                                        // Ensure both team1 and team2 exist before proceeding
                                                                                                        if (team1 && team2) {
                                                                                                            const leftTeam = team1.score > team2.score ? team1 : team2;
                                                                                                            const rightTeam = team1.score > team2.score ? team2 : team1;

                                                                                                            return (
                                                                                                                <div className="team-result-card" key={index} onClick={() => handleCardClick(leftTeam.gameId, leftTeam.userId)}>
                                                                                                                    <div className="team-left-card">
                                                                                                                        <div className="team-img">

                                                                                                                            <img src={leftTeam?.badge ? teamImage(leftTeam?.badge) : '/assets/images/manchester-logo.svg'} alt={leftTeam?.name} />
                                                                                                                        </div>
                                                                                                                        <h3>{leftTeam?.name}</h3>
                                                                                                                    </div>
                                                                                                                    <div className="result-stat">
                                                                                                                        <p className="result-text">
                                                                                                                            <span className="score lead-score">{leftTeam?.score}</span> ‐
                                                                                                                            <span className="score trail-score">{rightTeam?.score}</span>
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                    <div className="team-right-card">
                                                                                                                        <div className="team-img">

                                                                                                                            <img src={rightTeam?.badge ? teamImage(rightTeam?.badge) : '/assets/images/liverpool-logo.svg'} alt={rightTeam?.name} />
                                                                                                                        </div>
                                                                                                                        <h3>{rightTeam?.name}</h3>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            )
                                                                                                        }
                                                                                                    })}
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                </div>

                                                                                <div className="team-statistics">
                                                                                    <div className="row g-3">
                                                                                        <div className='col-12'>

                                                                                            <div className='inner_wrapper_card'>

                                                                                                <div className="flex-content">

                                                                                                    <div className="common-info-detail">
                                                                                                        <h2 className="h4">Goals</h2>
                                                                                                        <p className="mb-0">{playerDetails?.overall?.goalsCount}</p>
                                                                                                    </div>
                                                                                                    <div className="common-info-detail">
                                                                                                        <h2 className="h4">Assists</h2>
                                                                                                        <p className="mb-0">{playerDetails?.overall?.assist}</p>
                                                                                                    </div>
                                                                                                    <div className="common-info-detail">
                                                                                                        <h2 className="h4">Clean Sheets</h2>
                                                                                                        <p className="mb-0">{playerDetails?.overall?.cleanSheetsCount}</p>
                                                                                                    </div>
                                                                                                    <div className="common-info-detail">
                                                                                                        <h2 className="h4">Goals Conceded</h2>
                                                                                                        <p className="mb-0">{playerDetails?.overall?.goalConcededCount}</p>
                                                                                                    </div>
                                                                                                    <div className="common-info-detail">
                                                                                                        <h2 className="h4">Penalty Saves</h2>
                                                                                                        <p className="mb-0">{playerDetails?.overall?.penaltySavesCount}</p>
                                                                                                    </div>
                                                                                                    <div className="common-info-detail">
                                                                                                        <h2 className="h4">Player of the Match</h2>
                                                                                                        <p className="mb-0">{playerDetails?.overall?.playerOfTheMatchCount}</p>
                                                                                                    </div>
                                                                                                    <div className="common-info-detail">
                                                                                                        <h2 className="h4">Captain</h2>
                                                                                                        <p className="mb-0">{playerDetails?.overall?.captainCount}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                            {/* </div> */}
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="team-statistics">
                                                                                    <div className="row g-3">
                                                                                        <div className='col-12'>
                                                                                            <div className='inner_wrapper_card'>
                                                                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                    <h2>Away</h2>
                                                                                                </div>
                                                                                                <div className='common_style_layout'>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Games Played</h3>
                                                                                                        <div className='common_style_value'>
                                                                                                            <p>{playerDetails?.overall?.away?.gamePlayedCount}</p>
                                                                                                            {/* <div className='v-divider'></div>
                                                                            <p>100%</p> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Minutes Played</h3>
                                                                                                        <p>{playerDetails?.overall?.away?.timePlayed}</p>
                                                                                                    </div>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Average Mins P/G</h3>
                                                                                                        <p>{playerDetails?.overall?.away?.averageMinutes}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='common_pie_style_layout'>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Won</h3>
                                                                                                        <p>{playerDetails?.overall?.away?.wins}</p>
                                                                                                        {/* <div className='v-divider'></div>
                                                                        <p>{playerDetails?.overall?.away?.winPercentage}</p> */}
                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.away?.winPercentage || 0} type="win" />
                                                                                                    </div>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Lost</h3>
                                                                                                        <p>{playerDetails?.overall?.away?.loss}</p>
                                                                                                        {/* <div className='v-divider'></div>
                                                                        <p>{playerDetails?.overall?.away?.lossPercentage}</p> */}
                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.away?.lossPercentage || 0} type="loss" />
                                                                                                    </div>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Drawn</h3>
                                                                                                        <p>{playerDetails?.overall?.away?.draw}</p>
                                                                                                        {/* <div className='v-divider'></div>
                                                                        <p>{playerDetails?.overall?.away?.drawPercentage}</p> */}
                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.away?.drawPercentage || 0} type="draw" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                            <div className="col-xl-6">
                                                                                <div className="team-statistics">
                                                                                    <div className="row g-3">
                                                                                        <div className='col-12'>
                                                                                            <div className='inner_wrapper_card'>
                                                                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                    <h2>Total</h2>
                                                                                                </div>
                                                                                                <div className='common_style_layout'>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Games Played</h3>
                                                                                                        <div className='common_style_value'>
                                                                                                            <p>{playerDetails?.overall?.gamePlayedCount}</p>
                                                                                                            {/* <div className='v-divider'></div>
                                                                            <p>100%</p> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Minutes Played</h3>
                                                                                                        <p>{playerDetails?.overall?.timePlayed}</p>
                                                                                                    </div>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Average Mins P/G</h3>
                                                                                                        <p>{playerDetails?.overall?.averageMinutes}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='common_pie_style_layout'>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Won</h3>
                                                                                                        <p>{playerDetails?.overall?.wins}</p>

                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.winPercentage || 0} type="win" />

                                                                                                    </div>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Lost</h3>
                                                                                                        <p>{playerDetails?.overall?.loss}</p>
                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.lossPercentage || 0} type="loss" />
                                                                                                    </div>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Drawn</h3>
                                                                                                        <p>{playerDetails?.overall?.draw}</p>
                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.drawPercentage || 0} type="draw" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="team-statistics">
                                                                                    <div className="row g-3">
                                                                                        <div className='col-12'>
                                                                                            <div className='inner_wrapper_card'>
                                                                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                    <h2>Home</h2>
                                                                                                </div>
                                                                                                <div className='common_style_layout'>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Games Played</h3>
                                                                                                        <div className='common_style_value'>
                                                                                                            <p>{playerDetails?.overall?.home?.gamePlayedCount}</p>
                                                                                                            {/* <div className='v-divider'></div>
                                                                            <p>100%</p> */}
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Minutes Played</h3>
                                                                                                        <p>{playerDetails?.overall?.home?.timePlayed}</p>
                                                                                                    </div>
                                                                                                    <div className='common_style_item'>
                                                                                                        <h3>Average Mins P/G</h3>
                                                                                                        <p>{playerDetails?.overall?.home?.averageMinutes}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='common_pie_style_layout'>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Won</h3>
                                                                                                        <p>{playerDetails?.overall?.home?.wins}</p>
                                                                                                        {/* <div className='v-divider'></div>
                                                                        <p>{playerDetails?.overall?.home?.winPercentage}</p> */}
                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.home?.winPercentage || 0} type="win" />
                                                                                                    </div>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Lost</h3>
                                                                                                        <p>{playerDetails?.overall?.home?.loss}</p>
                                                                                                        {/* <div className='v-divider'></div>
                                                                        <p>{playerDetails?.overall?.home?.lossPercentage}</p> */}
                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.home?.winPercentage || 0} type="loss" />
                                                                                                    </div>
                                                                                                    <div className='common_pie_style_item'>
                                                                                                        <h3>Drawn</h3>
                                                                                                        <p>{playerDetails?.overall?.home?.draw}</p>
                                                                                                        {/* <div className='v-divider'></div>
                                                                        <p>{playerDetails?.overall?.home?.drawPercentage}</p> */}
                                                                                                        <CustomPieChart percentage={playerDetails?.overall?.home?.winPercentage || 0} type="draw" />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                {/* <div className="col-12 col-sm-6">
                                                            <div className="statistics-card">
                                                                <div className="statistics-info">
                                                                    <h3>Total Minutes Played</h3>
                                                                    <h4>{playerDetails?.overall?.timePlayed}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-6">
                                                            <div className="statistics-card">
                                                                <div className="statistics-info">
                                                                    <h3>Total Goal Assists</h3>
                                                                    <h4>{playerDetails?.overall?.assists}</h4>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                                                {/* <div className="col-12 col-sm-12">
                                                            <div className="statistics-card">
                                                                <div className="positions-stat statistics-info gap-3">
                                                                    <h3 className="fw-bold fs-5">Positions</h3>
                                                                    <div className="d-flex justify-content-between w-100">
                                                                        <h3>Attacker</h3>
                                                                        <h4>{playerDetails?.overall?.positions?.attacker}</h4>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between w-100">
                                                                        <h3>Defender</h3>
                                                                        <h4>{playerDetails?.overall?.positions?.defender}</h4>
                                                                    </div>
                                                                    <div className="d-flex justify-content-between w-100">
                                                                        <h3>Goalkeeper</h3>
                                                                        <h4>{playerDetails?.overall?.positions?.goalKeeper}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                                                {/* <div className="col-12 col-sm-6">
                                                            <div className="statistics-card">
                                                                <div className="statistics-info">
                                                                    <h3 className="fw-bold fs-5">Man of the match</h3>
                                                                    <h4>{playerDetails?.overall?.playerOfTheMatch}</h4>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                                                {/* <div className="col-12 col-sm-6">
                                                            <div className="statistics-card">
                                                                <div className="statistics-info">
                                                                    <h3 className="fw-bold fs-5">Captain</h3>
                                                                    <h4>{playerDetails?.overall?.captain}</h4>
                                                                </div>
                                                            </div>
                                                        </div> */}
                                                                                <div className="team-statistics">
                                                                                    <div className="row g-3">
                                                                                        <div className="col-12">
                                                                                            <div className="booking_blk card-flex-content flex-column">
                                                                                                <h2>Bookings</h2>
                                                                                                <div className="statistics-card flex-column align-items-center">
                                                                                                    <div className="statistics-info">
                                                                                                        <h3>Yellow Card</h3>
                                                                                                        <div className="match-card">
                                                                                                            <div className="card-type yellow-card"></div>
                                                                                                            <div className="match-card-count">{playerDetails?.overall?.bookings?.yellowCardsCount}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="statistics-info">
                                                                                                        <h3>Red Card</h3>
                                                                                                        <div className="match-card">
                                                                                                            <div className="card-type red-card"></div>
                                                                                                            <div className="match-card-count">{playerDetails?.overall?.bookings?.redCardsCount}</div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>

                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    {/* <div className="wrapper-card">
                                        <div className="row g-3">
                                            <div className="col-xl-6">
                                                <div className="team-statistics">
                                                    <div className="bg-white-statistics">
                                                        <div className="row g-3 align-items-center">
                                                            <div className="col-12">
                                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                                    <h2>How Scored</h2>
                                                                    <h3>{playerDetails?.overall?.totalGoalsScored}</h3>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-5'>
                                                                <div className='row'>
                                                                    <div className="col-sm-12">
                                                                        <div className="how-score-blk">
                                                                            <div className="how-score-icon">
                                                                                <img src='/assets/images/right-foot.png' alt="Foot" />
                                                                            </div>
                                                                            <div className="how-score-info">
                                                                                <h3>{playerDetails?.overall?.howScored?.rightFootCount}</h3>
                                                                                <p>Right foot</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="how-score-blk">
                                                                            <div className="how-score-icon">
                                                                                <img src='/assets/images/left-foot.png' alt="Foot" />
                                                                            </div>
                                                                            <div className="how-score-info">
                                                                                <h3>{playerDetails?.overall?.howScored?.leftFootCount}</h3>
                                                                                <p>Left foot</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="how-score-blk">
                                                                            <div className="how-score-icon">
                                                                                <img src='/assets/images/head.png' alt="Head" />
                                                                            </div>
                                                                            <div className="how-score-info">
                                                                                <h3>{playerDetails?.overall?.howScored?.headedCount}</h3>
                                                                                <p>Head</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="col-sm-12">
                                                                        <div className="how-score-blk border-bottom-0">
                                                                            <div className="how-score-icon">
                                                                                <img src='/assets/images/other.png' alt="By Other" />
                                                                            </div>
                                                                            <div className="how-score-info">
                                                                                <h3>{playerDetails?.overall?.howScored?.otherCount}</h3>
                                                                                <p>Other</p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className='col-lg-7'>
                                                                <div className='how_pie_graph_blk'></div>
                                                            </div>

                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            <div className="col-xl-6">
                                                <div className="team-statistics">
                                                    <div className="row g-3">
                                                        <div className="col-12">
                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                <h2>Total Goals Conceded</h2>
                                                                <h3>{playerDetails?.overall?.totalGoalsConceded}</h3>
                                                            </div>
                                                        </div>
                                                        <div className="col-12 col-sm-12">
                                                            <h2 className="h6">How Conceded</h2>
                                                            <div className="statistics-card">
                                                                <div className="statistics-info">
                                                                    <h3>From Penalty</h3>
                                                                    <h4>{playerDetails?.overall?.howConceded?.penalty}</h4>
                                                                </div>
                                                                <div className="statistics-info">
                                                                    <h3>Free kick direct</h3>
                                                                    <h4>{playerDetails?.overall?.howConceded?.directFreeKick}</h4>
                                                                </div>
                                                                <div className="statistics-info">
                                                                    <h3>Open play</h3>
                                                                    <h4>{playerDetails?.overall?.howConceded?.openPlay}</h4>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="card-flex-content">
                                                                <h2>Where From</h2>
                                                                <div className="statistics-card">
                                                                    <div className="statistics-info">
                                                                        <h3>Inside the box</h3>
                                                                        <h4 className="text-center">{playerDetails?.overall?.whereConceded?.insideTheBoxCount}</h4>
                                                                    </div>
                                                                    <div className="statistics-info">
                                                                        <h3>Outside the box</h3>
                                                                        <h4>{playerDetails?.overall?.whereConceded?.outsideTheBoxCount}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div className="col-12">
                                                            <div className="card-flex-content">
                                                                <h2>Goals Saved</h2>
                                                                <div className="statistics-card">
                                                                    <div className="statistics-info">
                                                                        <h3>Penalty</h3>
                                                                        <h4 className="text-center">{playerDetails?.overall?.goalsSaved?.penalty}</h4>
                                                                    </div>
                                                                    <div className="statistics-info">
                                                                        <h3>Open play</h3>
                                                                        <h4>{playerDetails?.overall?.goalsSaved?.openPlay}</h4>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div> */}
                                                                </div>
                                                                <div className="tab-pane fade" id="pills-attack" role="tabpanel" aria-labelledby="pills-attack-tab" tabIndex={0}>
                                                                    <div className="tab-pane fade show active" id="pills-overall" role="tabpanel" aria-labelledby="pills-overall-tab" tabIndex={0}>
                                                                        <div className="wrapper-card">
                                                                            <div class="common-sub-heading">
                                                                                <h2>Match List</h2>
                                                                            </div>
                                                                            <div className="row g-3">
                                                                                <div className="col-xl-6">
                                                                                    <div className="team-statistics">
                                                                                        <div className="row g-3">
                                                                                            <div className='col-12'>
                                                                                                <div className="team-result-card-list">
                                                                                                    {
                                                                                                        playerDetails?.listWithTeams?.length > 0 && playerDetails.listWithTeams.map((teamsArray, index) => {
                                                                                                            // Find team1 and team2 in each teamsArray
                                                                                                            const team1 = teamsArray.teams.find(t => t.type === 'Self');
                                                                                                            const team2 = teamsArray.teams.find(t => t.type === 'Opponent');

                                                                                                            // Ensure both team1 and team2 exist before proceeding
                                                                                                            if (team1 && team2) {
                                                                                                                const leftTeam = team1.score > team2.score ? team1 : team2;
                                                                                                                const rightTeam = team1.score > team2.score ? team2 : team1;

                                                                                                                return (
                                                                                                                    <div className="team-result-card" key={index}>
                                                                                                                        <div className="team-left-card">
                                                                                                                            <div className="team-img">

                                                                                                                                <img src={leftTeam?.badge ? teamImage(leftTeam?.badge) : '/assets/images/manchester-logo.svg'} alt={leftTeam?.name} />
                                                                                                                            </div>
                                                                                                                            <h3>{leftTeam?.name}</h3>
                                                                                                                        </div>
                                                                                                                        <div className="result-stat">
                                                                                                                            <p className="result-text">
                                                                                                                                <span className="score lead-score">{leftTeam?.score}</span> ‐
                                                                                                                                <span className="score trail-score">{rightTeam?.score}</span>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div className="team-right-card">
                                                                                                                            <div className="team-img">

                                                                                                                                <img src={rightTeam?.badge ? teamImage(rightTeam?.badge) : '/assets/images/liverpool-logo.svg'} alt={rightTeam?.name} />
                                                                                                                            </div>
                                                                                                                            <h3>{rightTeam?.name}</h3>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="team-statistics">
                                                                                        <div className="bg-white-statistics">
                                                                                            <div className="row g-3 align-items-center">
                                                                                                <div className="col-12">
                                                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                        <h2>How Scored</h2>
                                                                                                        {/* <h3>{playerDetails?.attack?.howScoredtotalGoalsScored}</h3> */}
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-lg-5'>
                                                                                                    <div className='row'>
                                                                                                        <div className="col-sm-12">
                                                                                                            <div className="how-score-blk">
                                                                                                                <div className="how-score-icon">
                                                                                                                    <img src='/assets/images/right-foot.png' alt="Foot" />
                                                                                                                </div>
                                                                                                                <div className="how-score-info">
                                                                                                                    <h3>{playerDetails?.attack?.howScored?.rightFootCount}</h3>
                                                                                                                    <p>Right foot</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-12">
                                                                                                            <div className="how-score-blk">
                                                                                                                <div className="how-score-icon">
                                                                                                                    <img src='/assets/images/left-foot.png' alt="Foot" />
                                                                                                                </div>
                                                                                                                <div className="how-score-info">
                                                                                                                    <h3>{playerDetails?.attack?.howScored?.leftFootCount}</h3>
                                                                                                                    <p>Left foot</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-12">
                                                                                                            <div className="how-score-blk">
                                                                                                                <div className="how-score-icon">
                                                                                                                    <img src='/assets/images/head.png' alt="Head" />
                                                                                                                </div>
                                                                                                                <div className="how-score-info">
                                                                                                                    <h3>{playerDetails?.attack?.howScored?.headedCount}</h3>
                                                                                                                    <p>Head</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className="col-sm-12">
                                                                                                            <div className="how-score-blk border-bottom-0">
                                                                                                                <div className="how-score-icon">
                                                                                                                    <img src='/assets/images/other.png' alt="By Other" />
                                                                                                                </div>
                                                                                                                <div className="how-score-info">
                                                                                                                    <h3>{playerDetails?.attack?.howScored?.otherCount}</h3>
                                                                                                                    <p>Other</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-lg-7'>
                                                                                                    <div className='how_pie_graph_blk'>
                                                                                                        <CustomPieChartTwo right={playerDetails?.attack?.howScored?.rightFootPercentage || 0} left={playerDetails?.attack?.howScored?.leftFootPercentage || 0} headed={playerDetails?.attack?.howScored?.headedPercentage || 0} other={playerDetails?.attack?.howScored?.otherPercentage || 0} />
                                                                                                    </div>
                                                                                                </div>

                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="team-statistics">
                                                                                        <div className="bg-white-statistics">
                                                                                            <div className="row g-3 align-items-center">
                                                                                                <div className="col-12">
                                                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                        <h2>Play When Scored</h2>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-lg-6'>
                                                                                                    <div className='how_pie_graph_blk'>
                                                                                                        <CustomPieChartFour openPlay={playerDetails?.attack?.playWhenScored?.openPlayPercentage || 0} penalty={playerDetails?.attack?.playWhenScored?.penaltyPercentage || 0} direct={playerDetails?.attack?.playWhenScored?.directFreeKickPercentage || 0} indirect={playerDetails?.attack?.playWhenScored?.indirectFreeKickPercentage || 0} corner={playerDetails?.attack?.playWhenScored?.cornerPercentage || 0} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-lg-6'>
                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#344BFD" }}>
                                                                                                                </span>
                                                                                                                <h3>Open Play</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.attack?.playWhenScored?.openPlayCount}</p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#FD3D59" }}>
                                                                                                                </span>
                                                                                                                <h3>Penalty</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.attack?.playWhenScored?.penaltyCount}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#000000" }}>
                                                                                                                </span>
                                                                                                                <h3>Direct Free Kick</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.attack?.playWhenScored?.directFreeKickCount}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#EFEFEF" }}>
                                                                                                                </span>
                                                                                                                <h3>Indirect Free Kick</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.attack?.playWhenScored?.indirectFreeKickPercentage}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#E2E2E2" }}>
                                                                                                                </span>
                                                                                                                <h3>Corner</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.attack?.playWhenScored?.cornerCount}</p>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-xl-6">

                                                                                    <div className="team-statistics">
                                                                                        <div className="row g-3">
                                                                                            <div className='col-12'>
                                                                                                <div className='inner_wrapper_card'>
                                                                                                    <div className='common_pie_style_layout layout-attack'>
                                                                                                        <div className='common_pie_style_item'>
                                                                                                            <h3>Goals</h3>
                                                                                                            <p>{playerDetails?.attack?.goalsCount}</p>


                                                                                                            <CustomPieChartFive percentage={playerDetails?.attack?.goalsPercentage || 0} type="goals" />
                                                                                                        </div>
                                                                                                        <div className='home-away'>
                                                                                                            <div className='common_pie_style_item'>
                                                                                                                <h3>Home</h3>
                                                                                                                <p>{playerDetails?.attack?.homeCount}</p>

                                                                                                                <CustomPieChartFive percentage={playerDetails?.attack?.homePercentage || 0} type="home" />
                                                                                                            </div>
                                                                                                            <div className='common_pie_style_item'>
                                                                                                                <h3>Away</h3>
                                                                                                                <p>{playerDetails?.attack?.awayCount}</p>

                                                                                                                <CustomPieChartFive percentage={playerDetails?.attack?.awayPercentage || 0} type="away" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <h4>Average Goals</h4>
                                                                                                    <div className='common_style_layout'>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Per Game</h3>
                                                                                                            <div className='common_style_value'>
                                                                                                                <p>{playerDetails?.attack?.averageGoalPerGame}</p>
                                                                                                                {/* <div className='v-divider'></div>
                                                                            <p>100%</p> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Home</h3>
                                                                                                            <p>{playerDetails?.attack?.averageGoalHome}</p>
                                                                                                        </div>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Away</h3>
                                                                                                            <p>{playerDetails?.attack?.averageGoalAway}</p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='row'>
                                                                                                        <div className='col-sm-6 mt-3'>
                                                                                                            <div className='common_style_layout attack-assist-layout'>
                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>Assists</h3>
                                                                                                                    <div className='common_style_value'>
                                                                                                                        <p>{playerDetails?.attack?.assist}</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='col-sm-6 mt-3'>
                                                                                                            <div className='common_style_layout attack-assist-layout'>
                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>Avg Assists Per Game</h3>
                                                                                                                    <div className='common_style_value'>
                                                                                                                        <p>{playerDetails?.attack?.averageAssist}</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>



                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="team-statistics">
                                                                                        <div className="row g-3">
                                                                                            <div className='col-12'>
                                                                                                <div className='inner_wrapper_card'>
                                                                                                    <div className="row g-3 align-items-center">
                                                                                                        <div className="col-12">
                                                                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                                <h2>Where Scored</h2>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='col-12'>
                                                                                                            <div className='where-scored d-flex justify-content-evenly'>
                                                                                                                <div className='common_pie_style_item'>
                                                                                                                    <h3>Inside the Box</h3>
                                                                                                                    <p>{playerDetails?.attack?.whereScored?.insideTheBoxCount}</p>

                                                                                                                    <CustomPieChartThree percentage={playerDetails?.attack?.whereScored?.insideTheBoxPercentage || 0} type="inside" />
                                                                                                                </div>
                                                                                                                <div className='common_pie_style_item'>
                                                                                                                    <h3>Outside the Box</h3>
                                                                                                                    <p>{playerDetails?.attack?.whereScored?.outsideTheBoxCount}</p>

                                                                                                                    <CustomPieChartThree percentage={playerDetails?.attack?.whereScored?.outsideTheBoxPercentage || 0} type="outside" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="team-statistics">
                                                                                        <div className="row g-3">
                                                                                            <div className='col-12'>
                                                                                                <div className='inner_wrapper_card'>
                                                                                                    <div className="row g-3 align-items-center">
                                                                                                        <div className="col-12">
                                                                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                                <h2>When Scored</h2>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='col-12'>
                                                                                                            <div className='common_style_layout'>
                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>1st Half</h3>

                                                                                                                    <p>{playerDetails?.attack?.whenScored?.firstHalfCount}</p>
                                                                                                                    <p>{playerDetails?.attack?.whenScored?.firstHalfPercentage}</p>

                                                                                                                </div>

                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>2nd Half</h3>
                                                                                                                    <p>{playerDetails?.attack?.whenScored?.secondHalfCount}</p>
                                                                                                                    <p>{playerDetails?.attack?.whenScored?.secondHalfPercentage}</p>
                                                                                                                </div>
                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>Extra Time</h3>
                                                                                                                    <p>{playerDetails?.attack?.whenScored?.extraTimeCount}</p>
                                                                                                                    <p>{playerDetails?.attack?.whenScored?.extraTimePercentage}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>

                                                                                    {/* <div className="team-statistics">
                                                        <div className="bg-white-statistics">
                                                            <div className="row g-3 align-items-center">
                                                                <div className="col-12">
                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                        <h2>How Conceded</h2>
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='how_pie_graph_blk'>
                                                                        <CustomPieChartTwo right={playerDetails?.attack?.howScored?.rightFootPercentage || 0} left={playerDetails?.attack?.howScored?.leftFootPercentage || 0} headed={playerDetails?.attack?.howScored?.headedPercentage || 0} other={playerDetails?.attack?.howScored?.otherPercentage || 0} />
                                                                    </div>
                                                                </div>
                                                                <div className='col-lg-6'>
                                                                    <div className='conceded-score-graph-detail'>
                                                                        <div className="conceded-score-blk">
                                                                            <div className="conceded-score-info">
                                                                                <span className="conceded-label-icon" style={{ backGround: "#344BFD" }}>
                                                                                </span>
                                                                                <h3>Open Play</h3>
                                                                            </div>
                                                                            <p>8</p>
                                                                        </div>
                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </div>
                                                    </div> */}
                                                                                </div>



                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="tab-pane fade" id="pills-defense" role="tabpanel" aria-labelledby="pills-defense-tab" tabIndex={0}>
                                                                    <div className="tab-pane fade show active" id="pills-overall" role="tabpanel" aria-labelledby="pills-overall-tab" tabIndex={0}>
                                                                        <div className="wrapper-card">
                                                                            <div class="common-sub-heading">
                                                                                <h2>Match List</h2>
                                                                            </div>
                                                                            <div className="row g-3">
                                                                                <div className="col-xl-6">
                                                                                    <div className="team-statistics">
                                                                                        <div className="row g-3">
                                                                                            <div className='col-12'>
                                                                                                <div className="team-result-card-list">
                                                                                                    {
                                                                                                        playerDetails?.listWithTeams?.length > 0 && playerDetails.listWithTeams.map((teamsArray, index) => {
                                                                                                            // Find team1 and team2 in each teamsArray
                                                                                                            const team1 = teamsArray.teams.find(t => t.type === 'Self');
                                                                                                            const team2 = teamsArray.teams.find(t => t.type === 'Opponent');

                                                                                                            // Ensure both team1 and team2 exist before proceeding
                                                                                                            if (team1 && team2) {
                                                                                                                const leftTeam = team1.score > team2.score ? team1 : team2;
                                                                                                                const rightTeam = team1.score > team2.score ? team2 : team1;

                                                                                                                return (
                                                                                                                    <div className="team-result-card" key={index}>
                                                                                                                        <div className="team-left-card">
                                                                                                                            <div className="team-img">

                                                                                                                                <img src={leftTeam?.badge ? teamImage(leftTeam?.badge) : '/assets/images/manchester-logo.svg'} alt={leftTeam?.name} />
                                                                                                                            </div>
                                                                                                                            <h3>{leftTeam?.name}</h3>
                                                                                                                        </div>
                                                                                                                        <div className="result-stat">
                                                                                                                            <p className="result-text">
                                                                                                                                <span className="score lead-score">{leftTeam?.score}</span> ‐
                                                                                                                                <span className="score trail-score">{rightTeam?.score}</span>
                                                                                                                            </p>
                                                                                                                        </div>
                                                                                                                        <div className="team-right-card">
                                                                                                                            <div className="team-img">

                                                                                                                                <img src={rightTeam?.badge ? teamImage(rightTeam?.badge) : '/assets/images/liverpool-logo.svg'} alt={rightTeam?.name} />
                                                                                                                            </div>
                                                                                                                            <h3>{rightTeam?.name}</h3>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                )
                                                                                                            }
                                                                                                        })}
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="team-statistics">
                                                                                        <div className="row g-3">
                                                                                            <div className='col-12'>
                                                                                                <div className='inner_wrapper_card'>
                                                                                                    <div className="row g-3 align-items-center">
                                                                                                        <div className="col-12">
                                                                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                                <h2>When Conceded</h2>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='col-12'>
                                                                                                            <div className='common_style_layout'>
                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>1st Half</h3>

                                                                                                                    <p>{playerDetails?.defence?.whenConceded?.firstHalfCount}</p>
                                                                                                                    <p>{playerDetails?.defence?.whenConceded?.firstHalfPercentage}</p>

                                                                                                                </div>

                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>2nd Half</h3>
                                                                                                                    <p>{playerDetails?.defence?.whenConceded?.secondHalfCount}</p>
                                                                                                                    <p>{playerDetails?.defence?.whenConceded?.secondHalfPercentage}</p>
                                                                                                                </div>
                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>Extra Time</h3>
                                                                                                                    <p>{playerDetails?.defence?.whenConceded?.extraTimeCount}</p>
                                                                                                                    <p>{playerDetails?.defence?.whenConceded?.extraTimePercentage}</p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="team-statistics">
                                                                                        <div className="row g-3">
                                                                                            <div className='col-12'>
                                                                                                <div className='inner_wrapper_card'>
                                                                                                    <div className="row g-3 align-items-center">
                                                                                                        <div className="col-12">
                                                                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                                <h2>Where Conceded</h2>
                                                                                                            </div>
                                                                                                        </div>

                                                                                                        <div className='col-12'>
                                                                                                            <div className='where-scored d-flex justify-content-evenly'>
                                                                                                                <div className='common_pie_style_item'>
                                                                                                                    <h3>Inside the Box</h3>
                                                                                                                    <p>{playerDetails?.defence?.whereConceded?.insideTheBoxCount}</p>

                                                                                                                    <CustomPieChartThree percentage={playerDetails?.defence?.whereConceded?.insideTheBoxPercentage || 0} type="inside" />
                                                                                                                </div>
                                                                                                                <div className='common_pie_style_item'>
                                                                                                                    <h3>Outside the Box</h3>
                                                                                                                    <p>{playerDetails?.defence?.whereConceded?.outsideTheBoxCount}</p>

                                                                                                                    <CustomPieChartThree percentage={playerDetails?.defence?.whereConceded?.outsideTheBoxPercentage || 0} type="outside" />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className='col-xl-6'>
                                                                                    <div className="team-statistics">
                                                                                        <div className="row g-3">
                                                                                            <div className='col-12'>
                                                                                                <div className='inner_wrapper_card'>
                                                                                                    <div className='common_pie_style_layout layout-attack'>
                                                                                                        <div className='common_pie_style_item'>
                                                                                                            <h3>Clean Sheets</h3>
                                                                                                            <p>{playerDetails?.defence?.cleanSheetsCount}</p>


                                                                                                            <CustomPieChartFive percentage={playerDetails?.defence?.cleanSheetsPercentage || 0} type="goals" />
                                                                                                        </div>
                                                                                                        <div className='home-away'>
                                                                                                            <div className='common_pie_style_item'>
                                                                                                                <h3>Home</h3>
                                                                                                                <p>{playerDetails?.defence?.homeCount}</p>

                                                                                                                <CustomPieChartFive percentage={playerDetails?.defence?.homePercentage || 0} type="home" />
                                                                                                            </div>
                                                                                                            <div className='common_pie_style_item'>
                                                                                                                <h3>Away</h3>
                                                                                                                <p>{playerDetails?.defence?.awayCount}</p>

                                                                                                                <CustomPieChartFive percentage={playerDetails?.defence?.awayPercentage || 0} type="away" />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='row'>
                                                                                                        <div className='col-sm-6 mt-3 mb-3'>
                                                                                                            <div className='common_style_layout attack-assist-layout'>
                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>Penalty Saves</h3>
                                                                                                                    <div className='common_style_value'>
                                                                                                                        <p>{playerDetails?.defence?.penaltySavesCount}</p>
                                                                                                                        <div className='v-divider'></div>
                                                                                                                        <p>{`${playerDetails?.defence?.penaltySavesPercentage}%`}</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='col-sm-6 mt-3 mb-3'>
                                                                                                            <div className='common_style_layout attack-assist-layout'>
                                                                                                                <div className='common_style_item'>
                                                                                                                    <h3>Penalty Conceded</h3>
                                                                                                                    <div className='common_style_value'>
                                                                                                                        <p>{playerDetails?.defence?.penaltyConceded}</p>
                                                                                                                        <div className='v-divider'></div>
                                                                                                                        <p>{`${playerDetails?.defence?.penaltyConcededPercentage}%`}</p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    {/* <h4>Average Goals</h4> */}
                                                                                                    <div className='common_style_layout mb-3'>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Goals Conceded</h3>
                                                                                                            <div className='common_style_value'>
                                                                                                                <p>{playerDetails?.defence?.goalConcededCount}</p>
                                                                                                                {/* <div className='v-divider'></div>
                                                                            <p>100%</p> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Home</h3>
                                                                                                            <p>{playerDetails?.defence?.goalConcededHomeCount}</p>
                                                                                                        </div>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Away</h3>
                                                                                                            <p>{playerDetails?.defence?.goalConcededAwayCount}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='common_style_layout'>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Average Conceded P/G</h3>
                                                                                                            <div className='common_style_value'>
                                                                                                                <p>{playerDetails?.defence?.averageConcededPercentage}</p>
                                                                                                                {/* <div className='v-divider'></div>
                                                                            <p>100%</p> */}
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Home</h3>
                                                                                                            <p>{playerDetails?.defence?.averageGoalConcededHomeCount}</p>
                                                                                                        </div>
                                                                                                        <div className='common_style_item'>
                                                                                                            <h3>Away</h3>
                                                                                                            <p>{playerDetails?.defence?.averageGoalConcededAwayCount}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="team-statistics">
                                                                                        <div className="bg-white-statistics">
                                                                                            <div className="row g-3 align-items-center">
                                                                                                <div className="col-12">
                                                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                        <h2>How Conceded</h2>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-lg-6'>
                                                                                                    <div className='how_pie_graph_blk'>
                                                                                                        <CustomPieChartFour openPlay={playerDetails?.defence?.howConceded?.openPlayPercentage || 0} penalty={playerDetails?.defence?.howConceded?.penaltyPercentage || 0} direct={playerDetails?.defence?.howConceded?.directFreeKickPercentage || 0} indirect={playerDetails?.defence?.howConceded?.indirectFreeKickPercentage || 0} corner={playerDetails?.defence?.howConceded?.cornerPercentage || 0} />
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-lg-6'>
                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#344BFD" }}>
                                                                                                                </span>
                                                                                                                <h3>Open Play</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.defence?.howConceded?.openPlayCount}</p>
                                                                                                        </div>
                                                                                                    </div>

                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#FD3D59" }}>
                                                                                                                </span>
                                                                                                                <h3>Penalty</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.defence?.howConceded?.penaltyCount}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#000000" }}>
                                                                                                                </span>
                                                                                                                <h3>Direct Free Kick</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.defence?.howConceded?.directFreeKickCount}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#EFEFEF" }}>
                                                                                                                </span>
                                                                                                                <h3>Indirect Free Kick</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.defence?.howConceded?.indirectFreeKickPercentage}</p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className='conceded-score-graph-detail'>
                                                                                                        <div className="conceded-score-blk">
                                                                                                            <div className="conceded-score-info">
                                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: "#E2E2E2" }}>
                                                                                                                </span>
                                                                                                                <h3>Corner</h3>
                                                                                                            </div>
                                                                                                            <p>{playerDetails?.defence?.howConceded?.cornerCount}</p>
                                                                                                        </div>

                                                                                                    </div>
                                                                                                </div>


                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="team-statistics">
                                                                                        <div className="bg-white-statistics">
                                                                                            <div className="row g-3 align-items-center">
                                                                                                <div className="col-12">
                                                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                        <h2>Where Beaten</h2>
                                                                                                    </div>
                                                                                                </div>
                                                                                                <div className='col-12'>
                                                                                                    <div className="ground_design_wrapper">
                                                                                                        <div className="box_wrapper">
                                                                                                            <div className="box_inner_wrapper">
                                                                                                                <div className="row row-cols-3 g-1">
                                                                                                                    <div className="col">
                                                                                                                        <div className="row gy-1">
                                                                                                                            <div className="col-12">
                                                                                                                                <div className="design-box bg-primary">{`${Math.round(playerDetails?.defence?.whenConceded?.whereBeaten?.[0])}%`}</div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12">
                                                                                                                                <div className="design-box bg-primary">{`${Math.round(playerDetails?.defence?.whenConceded?.whereBeaten?.[3])}%`}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col">
                                                                                                                        <div className="row gy-1">
                                                                                                                            <div className="col-12">
                                                                                                                                <div className="design-box bg-custom-secondary">{`${Math.round(playerDetails?.defence?.whenConceded?.whereBeaten?.[1])}%`}</div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12">
                                                                                                                                <div className="design-box bg-custom-secondary">{`${Math.round(playerDetails?.defence?.whenConceded?.whereBeaten?.[4])}%`}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <div className="col">
                                                                                                                        <div className="row gy-1">
                                                                                                                            <div className="col-12">
                                                                                                                                <div className="design-box bg-black">{`${Math.round(playerDetails?.defence?.whenConceded?.whereBeaten?.[2])}%`}</div>
                                                                                                                            </div>
                                                                                                                            <div className="col-12">
                                                                                                                                <div className="design-box bg-black">{`${Math.round(playerDetails?.defence?.whenConceded?.whereBeaten?.[5])}%`}</div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </section>
                                            </div>
                                        </div>
                                    )
                                    }


                                </div >
                            </section >
                        </div >)
            }

        </>
    )
}

export default AgentDetail