// src/components/Header.js
import React, { useContext, useEffect, useState } from 'react';
import { Link, useNavigate, useLocation } from 'react-router-dom';
import { AuthContext } from '../context/AuthContext';
import { useProfileImage } from '../context/ProfileImageContext';
import { useProfileNameContext } from '../context/ProfileNameContext';
import { fetchUserProfile } from '../api/fetchUserProfile';
import $ from 'jquery';
import iziToast from 'izitoast';

const Header = () => {
    const { profileImage } = useProfileImage();
    const { name } = useProfileNameContext();
    const { logout } = useContext(AuthContext);
    const navigate = useNavigate();
    const location = useLocation();
    const [profileImageNew, setProfileImageNew] = useState('')

    useEffect(() => {
        const getUserProfile = async () => {
            try {
                const profileData = await fetchUserProfile();
                if (profileData) {

                    if (profileData.image) {

                        setProfileImageNew(`${process.env.REACT_APP_BACKEND_URL}/public/${profileData.image}`);
                        // setProfileImage(`http://192.168.10.234:3026/public/${profileData.image}`);

                    }
                    else {
                        setProfileImageNew('/assets/images/user.png');
                    }

                }

            } catch (error) {
                console.error('Error fetching user profile', error);
            }
        };

        getUserProfile();
    }, [])

    useEffect(() => {
        // Add the jQuery click event for the open button
        $(".opnBtn").on("click", function () {
            $(".side-navbar").toggleClass("side-hide");
            $(".main-wrapper").toggleClass("main-width");
        });

        // Clean up the event listener when the component unmounts
        return () => {
            $(".opnBtn").off("click");
        };
    }, []);
    // Determine the current section based on the pathname
    const getPageHeading = () => {
        switch (location.pathname) {
            case '/admin-dashboard':
                return 'Dashboard';
            case '/admin-dashboard/admin-profile':
                return 'Profile';
            case '/admin-dashboard/notification':
                return 'Notifications';
            case '/admin-dashboard/player-management':
                return 'Players Management';
            case '/admin-dashboard/supporters-management':
                return 'Supporters Management';
            case '/admin-dashboard/agent-management':
                return 'Agent Management';
            case '/admin-dashboard/content-management':
                return 'Content Management';
            case '/admin-dashboard/team-management':
                return 'Team Management';
            case '/admin-dashboard/manager-management':
                return 'Manager Management';
            case '/admin-dashboard/push-notifications':
                return 'Notifications';
            case '/admin-dashboard/player-detail':
                return name ? `${name} Details` : 'Player Details';
            case '/admin-dashboard/agent-detail':
                return name ? `${name} Details` : 'Agent Details';
            case '/admin-dashboard/manager-detail':
                return name ? `${name} Details` : 'Manager Details';
            case '/admin-dashboard/supporter-detail':
                return name ? `${name} Details` : 'Supporter Details';
            default:
                return 'Page Not Found';
        }
    };

    const handleLogout = (event) => {
        event.preventDefault();
        iziToast.show({

            title: 'Delete Confirmation',
            message: 'Are you sure you want to log out?',
            buttons: [
                ['<button>Yes</button>', function (instance, toast) {
                    logout();
                    navigate('/');
                    instance.hide({ transitionOut: 'fadeOut' }, toast);
                }],
                ['<button>No</button>', function (instance, toast) {
                    instance.hide({ transitionOut: 'fadeOut' }, toast);
                }]
            ],
            position: 'topCenter',
        });
    };

    return (
        <header>

            <nav className="navbar-expand-sm navbar-light">
                <div className="container">
                    <div className="row justify-content-between align-items-center">
                        <div className="col-9">
                            <div className="header-flex">
                                <button className="opnBtn" type="button" style={{ display: 'inline-block' }}>
                                    <i className="fa-solid fa-bars"></i>
                                </button>
                                <span className="page-heading">{getPageHeading()}</span>
                            </div>
                        </div>

                        <div className="col-3">
                            <div className="header-menu">
                                <ul className="navbar-nav justify-content-end ms-auto mb-0 navsbar">
                                    <li className="nav-item">
                                        <Link to="#" className="top-nav-link logout-link" onClick={handleLogout}>
                                            <img src="/assets/images/logout-icon.svg" alt="Logout" />
                                        </Link>
                                    </li>
                                    {/* <li className="nav-item">
                                        <Link to="/admin-dashboard/notification" className="top-nav-link notification-link">
                                            <img src="/assets/images/notification-icon.svg" alt="Notification" />
                                        </Link>
                                    </li> */}
                                    <li className="nav-item">
                                        <div className="profile-imgs">
                                            <Link to="/admin-dashboard/admin-profile" className="nav-link profile-link">
                                                <img src={profileImage || profileImageNew} alt="Profile" />
                                            </Link>
                                        </div>
                                    </li>
                                </ul>
                            </div>
                        </div>
                    </div>
                </div>
            </nav>
        </header>
    );
};

export default Header;
