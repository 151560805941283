import React from 'react';
import {
    PieChart,
    Pie,
    Cell,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

const COLORS = {
    inside: '#0086FF',
    outside: '#FD3D59',
};

const GREY_COLOR = '#E6E6EC';

const CustomPieChartThree = ({ percentage, type }) => {

    const validPercentage = Math.max(0, Math.min(percentage, 100));

    const data = [
        { name: 'Filled', value: validPercentage },
        { name: 'Remaining', value: 100 - validPercentage },

    ];

    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, name }) => {


        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));
        if (validPercentage > 0 && name === 'Filled') {
            return <text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontSize="10px">
                {`${Math.round(validPercentage)}%`}
            </text>
        }
    }

    return (
        <ResponsiveContainer width={200} height={200}>
            <PieChart>
                <Tooltip />
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={70}
                    startAngle={450}
                    endAngle={90}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    paddingAngle={0}
                    dataKey="value"

                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={index === 0 ? COLORS[type] : GREY_COLOR}
                            fillOpacity={1}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer >
    );
}

export default CustomPieChartThree;
