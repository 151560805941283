import React from 'react';
import {
    PieChart,
    Pie,
    Cell,
    Tooltip,
    ResponsiveContainer,
} from 'recharts';

const COLORS = {
    open: '#344BFD',  // Blue for right
    penalty: '#FD3D59',   // Red for left
    direct: '#000000',  // Black for headed
    indirect: '#E6E6EC',
    corner: '#E2E2E2'
};

const GREY_COLOR = '#E6E6EC';

const CustomPieChartFour = ({ openPlay, penalty, direct, indirect, corner }) => {
    // Prepare data for the pie chart
    let data = [
        { name: 'Open', value: openPlay },
        { name: 'Penalty', value: penalty },
        { name: 'Direct', value: direct },
        { name: 'Indirect', value: indirect },
        { name: 'Corner', value: corner },
    ];

    data = data.filter(entry => entry.value > 0);

    const total = data.reduce((sum, entry) => sum + entry.value, 0);

    if (total === 0) {
        data = [{ name: 'Empty', value: 100 }];
    }
    // Function to render customized labels
    const renderCustomizedLabel = ({ cx, cy, midAngle, innerRadius, outerRadius, value }) => {
        const radius = innerRadius + (outerRadius - innerRadius) * 0.5;
        const x = cx + radius * Math.cos(-midAngle * (Math.PI / 180));
        const y = cy + radius * Math.sin(-midAngle * (Math.PI / 180));


        if (total > 0) {
            return (<text x={x} y={y} fill="white" textAnchor="middle" dominantBaseline="central" fontSize="12px">
                {`${Math.round(value)}%`}
            </text>)
        }


    };

    return (
        <ResponsiveContainer width={200} height={200}>
            <PieChart>
                <Tooltip />
                <Pie
                    data={data}
                    cx="50%"
                    cy="50%"
                    outerRadius={100}
                    innerRadius={70}
                    startAngle={450}
                    endAngle={90}
                    labelLine={false}
                    label={renderCustomizedLabel}
                    paddingAngle={0}
                    dataKey="value"
                >
                    {data.map((entry, index) => (
                        <Cell
                            key={`cell-${index}`}
                            fill={total === 0 ? GREY_COLOR : COLORS[entry.name.toLowerCase()]}
                        />
                    ))}
                </Pie>
            </PieChart>
        </ResponsiveContainer>
    );
};

export default CustomPieChartFour;
