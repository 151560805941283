import React, { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import axiosInstance from '../api/axiosInstance';
import Select from 'react-select';
import CustomPieChart from '../common/PieChart';
import CustomPieChartFive from '../common/AttackPieChartFour';
import CustomPieChartThree from '../common/AttackPieChartTwo';
import CustomPieChartTwo from '../common/AttackPieChartOne';
import CustomPieChartFour from '../common/AttackPieChartThree';

const ManagerDetail = () => {
    const teamImage = (url) => {
        return `${process.env.REACT_APP_BACKEND_URL}/public/${url}`
    };

    const [manager, setManager] = useState(null);
    // const [teams, setTeams] = useState(null);
    // const [team, setSelectedTeam] = useState(null);
    const [teamData, setTeamData] = useState(null);
    const [filterData, setFilterData] = useState(null);
    const [season, setSelectedSeason] = useState(null);
    const [filt, setFilt] = useState(null);
    const location = useLocation();
    const queryParams = new URLSearchParams(location.search);

    const userId = queryParams.get('id');

    useEffect(() => {
        const fetchManager = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const url = new URL(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_MANAGER_DETAIL}`);
                url.searchParams.set('userId', userId);

                if (filt) {
                    url.searchParams.set('teamName', filt);
                }
                const response = await axiosInstance.get(url.toString(), {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response, "manager data");
                setManager(response.data.data);
                // setTeams(response.data.data.teamNames);

            } catch (error) {
                console.error('Failed to fetch manager details');
            }
        }
        if (userId) {
            fetchManager();
        } else {
            console.error('No manager ID provided');
        }
    }, [userId, filt]);

    useEffect(() => {
        const fetchFilterData = async () => {
            try {
                const token = localStorage.getItem('authToken');
                const url = new URL(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_MANAGER_FILTER_DATA}`);
                url.searchParams.set('userId', userId);

                console.log(url, "filter data url")
                const response = await axiosInstance.get(url.toString(), {
                    headers: {
                        Authorization: `Bearer ${token}`
                    }
                });
                console.log(response.data.data, "filter data")
                setFilterData(response.data.data);

                const options = response.data.data.teams.map(team => ({
                    value: team.name, label: (
                        <div style={{ display: 'flex', alignItems: 'center' }}>
                            <img src={team?.badge ? team?.badge : '/assets/images/manchester-logo.svg'} alt={team.name} style={{ width: 30, height: 30, borderRadius: '50%', marginRight: 10 }} />
                            {team.name}
                        </div>
                    )
                }));
                setTeamData(options);

                console.log(options, "option")

                if (options.length > 0 && !filt) {
                    setFilt(options[0].value);
                }
            }
            catch (error) {
                console.error('Failed to fetch filter data');
            }
        }
        if (userId) {
            fetchFilterData();
        }
        else {
            console.error('No manager ID provided');
        }
    }, [userId, filt]);

    // const handleTeamClick = async (teamName) => {
    //     setSelectedTeam(teamName);

    //     try {
    //         const token = localStorage.getItem('authToken');
    //         const url = new URL(`${process.env.REACT_APP_BACKEND_URL}${process.env.REACT_APP_MANAGER_DETAILS_ENDPOINT}`);
    //         url.searchParams.set("id", managerId);
    //         url.searchParams.set("teamName", teamName);

    //         const response = await axiosInstance.get(url, {
    //             headers: {
    //                 Authorization: `Bearer ${token}`
    //             }
    //         });
    //         console.log(response.data.data.teamStats, "teamstats")
    //         setTeamData(response.data.data.teamStats);

    //     } catch (error) {
    //         console.error('Failed to fetch team statistics');
    //     }
    // };

    const handleTeamChange = (selectedOption) => {
        setFilt(selectedOption.value);
    };

    const handleSeasonChange = (e) => {
        setSelectedSeason(e.target.value);

    };

    const handleGameTypeChange = (e) => {
        setSelectedGameType(e.target.value);

    };

    return (
        <div className='maanger-detail'>
            <div className="content-wrapper">
                <section className="main-section coach-section spacer">
                    <div className="container">

                        <div className="wrapper-card">
                            <div className='select_team_drop'>
                                <Select
                                    value={teamData ? teamData.find(option => option.value === filt) : null}
                                    onChange={handleTeamChange}
                                    options={teamData || []}
                                    isSearchable={false}
                                />
                            </div>
                            <div className="inner-bg-card">
                                <div className="row">
                                    <div className="col-12 col-sm-6">
                                        <div className="team-card bg-white">
                                            <div className="team-img">
                                                <img src="images/manchester-logo.svg" alt="Manchester United" />
                                            </div>
                                            <h3 className='fs-5'>Manchester United</h3>
                                        </div>
                                    </div>

                                    <div className="col-12 col-sm-6">
                                        <div className="card-flex-content" style={{ boxShadow: "0px 5px 8px rgb(0 0 0 / 12%)" }}>
                                            <h2>Bookings</h2>
                                            <div className="statistics-card">
                                                <div className="statistics-info gap-1">
                                                    <h3>Yellow Card</h3>
                                                    <div className="match-card">
                                                        <div className="card-type sm_card_type yellow-card" />
                                                        <div className="match-card-count">{manager?.overall?.bookings?.yellowCardsCount}</div>
                                                    </div>
                                                </div>
                                                <div className="statistics-info gap-1">
                                                    <h3>Red Card</h3>
                                                    <div className="match-card">
                                                        <div className="card-type sm_card_type red-card" />
                                                        <div className="match-card-count">{manager?.overall?.bookings?.redCardsCount}</div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>

                                </div>
                            </div>
                        </div>
                        <div className="team-stats-wrapper-card">
                            <div className="row g-3">
                                <div className="col-xl-12">
                                    <div className="select-team-statistics">
                                        <div className="form-block">
                                            <select className="form-select" onChange={handleSeasonChange}>
                                                <option value="">Stats of Seasons</option>
                                                {filterData?.seasons?.map((season, index) => (
                                                    <option key={index} value={season?._id}>
                                                        {season?.name}
                                                    </option>
                                                ))}
                                            </select>
                                        </div>
                                        <div className="form-block">
                                            <select className="form-select" onChange={handleGameTypeChange}>
                                                <option value="">Stats of Game Type</option>
                                                <option value="League">League</option>
                                                <option value="Cup/Tournament">Cup/Tournament</option>
                                                <option value="Friendly">Friendly</option>
                                            </select>
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>

                        <div className='newtab_blk'>
                            <div>
                                <ul className="nav nav-pills mb-3 srp_tabs" id="pills-tab-new" role="tablist">
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link active" id="pills-statistics-tab" data-bs-toggle="pill" data-bs-target="#pills-statistics" type="button" role="tab" aria-controls="pills-statistics" aria-selected="true">Statistics</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-rankings-tab" data-bs-toggle="pill" data-bs-target="#pills-rankings" type="button" role="tab" aria-controls="pills-rankings" aria-selected="false">Rankings</button>
                                    </li>
                                    <li className="nav-item" role="presentation">
                                        <button className="nav-link" id="pills-players-tab" data-bs-toggle="pill" data-bs-target="#pills-players" type="button" role="tab" aria-controls="pills-players" aria-selected="false">Players</button>
                                    </li>
                                </ul>
                                <div className="tab-content" id="pills-tab-newContent">
                                    <div className="tab-pane fade show active" id="pills-statistics" role="tabpanel" aria-labelledby="pills-statistics-tab" tabIndex={0}>
                                        <div className='commontab_wrapper'>
                                            <ul className="nav nav-pills mb-3" id="pills-tab" role="tablist">
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link active" id="pills-overall-tab" data-bs-toggle="pill" data-bs-target="#pills-overall" type="button" role="tab" aria-controls="pills-overall" aria-selected="true">Overall</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-attack-tab" data-bs-toggle="pill" data-bs-target="#pills-attack" type="button" role="tab" aria-controls="pills-attack" aria-selected="false">Attack</button>
                                                </li>
                                                <li className="nav-item" role="presentation">
                                                    <button className="nav-link" id="pills-defense-tab" data-bs-toggle="pill" data-bs-target="#pills-defense" type="button" role="tab" aria-controls="pills-defense" aria-selected="false">Defense</button>
                                                </li>
                                            </ul>
                                            <div className="tab-content" id="pills-tabContent">
                                                <div className="tab-pane fade show active" id="pills-overall" role="tabpanel" aria-labelledby="pills-overall-tab" tabIndex={0}>
                                                    <div className="wrapper-card">
                                                        {/* <div className="common-sub-heading">
                                                            <h2>Match List</h2>
                                                        </div> */}
                                                        <div className="row g-3">
                                                            <div className="col-xl-6">
                                                                {/* <div className="team-statistics">
                                                                    <div className="row g-3">
                                                                        <div className="col-12">
                                                                            <div className="team-result-card-list">
                                                                                <div className="team-result-card">
                                                                                    <div className="team-left-card">
                                                                                        <div className="team-img">
                                                                                            <img src="http://192.168.10.234:3026/public/uploads/teams/badges/b8Lx8jXJ7jLqhUHe7Tr0.png" alt="Nike" />
                                                                                        </div>
                                                                                        <h3>Nike</h3>
                                                                                    </div>
                                                                                    <div className="result-stat">
                                                                                        <p className="result-text">
                                                                                            <span className="score lead-score">2</span> ‐ <span className="score trail-score">1</span>
                                                                                        </p>
                                                                                    </div>
                                                                                    <div className="team-right-card">
                                                                                        <div className="team-img">
                                                                                            <img src="/assets/images/liverpool-logo.svg" alt="Beans" />
                                                                                        </div>
                                                                                        <h3>Beans</h3>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div> */}
                                                                <div className="team-statistics">
                                                                    <div className="row g-3">
                                                                        <div className="col-12">
                                                                            <div className="inner_wrapper_card">

                                                                                <div className="common_pie_style_layout">
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Won</h3>
                                                                                        <p>{manager?.overall?.wins}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts14-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Filled" stroke="#fff" fill="#0086FF" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    0,1,
    95.65071278216737,35.16718427000254
  L 76.6295824562643,41.34752415750148
      A 28,28,0,
      0,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 95.65071278216737,35.16718427000254
    A 48,48,0,
    1,1,
    50,2
  L 50,22
      A 28,28,0,
      1,0,
      76.6295824562643,41.34752415750148 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text x="72.33583958711398" y="19.257354213752006" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="10px">20%</text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="10.030602844111819" y="105.01315561749641" className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="10.030602844111819" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.winPercentage || 0} type="win" />
                                                                                    </div>
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Lost</h3>
                                                                                        <p>{manager?.overall?.loss}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts18-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1} />
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    49.9991622419591,2.000000007310817
  L 49.99951130780948,22.000000004264646
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Filled" fillOpacity={1} alignmentBaseline="middle" x="50.00000000000002" y={-18} className="recharts-text recharts-pie-label-text" textAnchor="start" fill=" #FD3D59">
                                                                                                                    <tspan x="50.00000000000002" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="49.999999999999986" y={118} className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="49.999999999999986" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                         
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.lossPercentage || 0} type="loss" />
                                                                                    </div>
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Drawn</h3>
                                                                                        <p>{manager?.overall?.draw}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts22-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1} />
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    49.9991622419591,2.000000007310817
  L 49.99951130780948,22.000000004264646
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Filled" fillOpacity={1} alignmentBaseline="middle" x="50.00000000000002" y={-18} className="recharts-text recharts-pie-label-text" textAnchor="start" fill="#000000">
                                                                                                                    <tspan x="50.00000000000002" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="49.999999999999986" y={118} className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="49.999999999999986" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.drawPercentage || 0} type="draw" />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="flex-content">
                                                                                    <div className="common-info-detail">
                                                                                        <h2 className="h4">Goals</h2>
                                                                                        <p className="mb-0">{manager?.overall?.goalsCount}</p>
                                                                                    </div>
                                                                                    <div className="common-info-detail">
                                                                                        <h2 className="h4">Assists</h2>
                                                                                        <p className="mb-0">{manager?.overall?.assist}</p>
                                                                                    </div>
                                                                                    <div className="common-info-detail">
                                                                                        <h2 className="h4">Clean Sheets</h2>
                                                                                        <p className="mb-0">{manager?.overall?.cleanSheetsCount}</p>
                                                                                    </div>
                                                                                    <div className="common-info-detail">
                                                                                        <h2 className="h4">Goals Conceded</h2>
                                                                                        <p className="mb-0">{manager?.overall?.goalConcededCount}</p>
                                                                                    </div>
                                                                                    <div className="common-info-detail">
                                                                                        <h2 className="h4">Penalty Saves</h2>
                                                                                        <p className="mb-0">{manager?.overall?.penaltySavesCount}</p>
                                                                                    </div>
                                                                                    {/* <div className="common-info-detail">
                                                                                        <h2 className="h4">Player of the Match</h2>
                                                                                        <p className="mb-0">2</p>
                                                                                    </div>
                                                                                    <div className="common-info-detail">
                                                                                        <h2 className="h4">Captain</h2>
                                                                                        <p className="mb-0">4</p>
                                                                                    </div> */}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="team-statistics">
                                                                    <div className="row g-3">
                                                                        <div className="col-12">
                                                                            <div className="inner_wrapper_card">
                                                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                                                    <h2>Away</h2>
                                                                                </div>
                                                                                <div className="common_style_layout">
                                                                                    <div className="common_style_item">
                                                                                        <h3>Games Played</h3>
                                                                                        <div className="common_style_value">
                                                                                            <p>{manager?.overall?.away?.gamePlayedCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="common_style_item">
                                                                                        <h3>Minutes Played</h3>
                                                                                        <p>{manager?.overall?.away?.timePlayed}</p>
                                                                                    </div>
                                                                                    <div className="common_style_item">
                                                                                        <h3>Average Mins P/G</h3>
                                                                                        <p>{manager?.overall?.away?.averageMinutes}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="common_pie_style_layout">
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Won</h3>
                                                                                        <p>{manager?.overall?.away?.wins}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts2-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Filled" stroke="#fff" fill="#0086FF" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    0,1,
    91.57424501814705,73.99129323673642
  L 74.25164292725245,63.99492105476292
      A 28,28,0,
      0,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 91.57424501814705,73.99129323673642
    A 48,48,0,
    1,1,
    50.00000000000002,2
  L 50.000000000000014,22
      A 28,28,0,
      1,0,
      74.25164292725245,63.99492105476292 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text x="82.90697548802146" y="30.996553885393432" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="10px">33%</text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="-8.886166662775224" y="84.00616673140124" className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="-8.886166662775224" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.away?.winPercentage || 0} type="win" />
                                                                                    </div>
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Lost</h3>
                                                                                        <p>{manager?.overall?.away?.loss}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts6-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1} />
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    49.9991622419591,2.000000007310817
  L 49.99951130780948,22.000000004264646
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Filled" fillOpacity={1} alignmentBaseline="middle" x="50.00000000000002" y={-18} className="recharts-text recharts-pie-label-text" textAnchor="start" fill=" #FD3D59">
                                                                                                                    <tspan x="50.00000000000002" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="49.999999999999986" y={118} className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="49.999999999999986" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.away?.winPercentage || 0} type="loss" />
                                                                                    </div>
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Drawn</h3>
                                                                                        <p>{manager?.overall?.away?.draw}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts10-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1} />
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    49.9991622419591,2.000000007310817
  L 49.99951130780948,22.000000004264646
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Filled" fillOpacity={1} alignmentBaseline="middle" x="50.00000000000002" y={-18} className="recharts-text recharts-pie-label-text" textAnchor="start" fill="#000000">
                                                                                                                    <tspan x="50.00000000000002" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="49.999999999999986" y={118} className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="49.999999999999986" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.away?.winPercentage || 0} type="draw" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div className="col-xl-6">
                                                                <div className="team-statistics">
                                                                    <div className="row g-3">
                                                                        <div className="col-12">
                                                                            <div className="inner_wrapper_card">
                                                                                <div className="top-heading d-flex justify-content-between mb-2">
                                                                                    <h2>Home</h2>
                                                                                </div>
                                                                                <div className="common_style_layout">
                                                                                    <div className="common_style_item">
                                                                                        <h3>Games Played</h3>
                                                                                        <div className="common_style_value">
                                                                                            <p>{manager?.overall?.home?.gamePlayedCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="common_style_item">
                                                                                        <h3>Minutes Played</h3>
                                                                                        <p>{manager?.overall?.home?.timePlayed}</p>
                                                                                    </div>
                                                                                    <div className="common_style_item">
                                                                                        <h3>Average Mins P/G</h3>
                                                                                        <p>{manager?.overall?.home?.averageMinutes}</p>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="common_pie_style_layout">
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Won</h3>
                                                                                        <p>{manager?.overall?.home?.wins}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts26-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1} />
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    49.9991622419591,2.000000007310817
  L 49.99951130780948,22.000000004264646
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Filled" fillOpacity={1} alignmentBaseline="middle" x="50.00000000000002" y={-18} className="recharts-text recharts-pie-label-text" textAnchor="start" fill="#0086FF">
                                                                                                                    <tspan x="50.00000000000002" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="49.999999999999986" y={118} className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="49.999999999999986" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.home?.winPercentage || 0} type="win" />
                                                                                    </div>
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Lost</h3>
                                                                                        <p>{manager?.overall?.home?.loss}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts30-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1} />
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    49.9991622419591,2.000000007310817
  L 49.99951130780948,22.000000004264646
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Filled" fillOpacity={1} alignmentBaseline="middle" x="50.00000000000002" y={-18} className="recharts-text recharts-pie-label-text" textAnchor="start" fill=" #FD3D59">
                                                                                                                    <tspan x="50.00000000000002" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="49.999999999999986" y={118} className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="49.999999999999986" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.home?.lossPercentage || 0} type="loss" />
                                                                                    </div>
                                                                                    <div className="common_pie_style_item">
                                                                                        <h3>Drawn</h3>
                                                                                        <p>{manager?.overall?.home?.draw}</p>
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts34-clip">
                                                                                                            <rect x={5} y={5} height={90} width={90} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1} />
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    49.9991622419591,2.000000007310817
  L 49.99951130780948,22.000000004264646
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Filled" fillOpacity={1} alignmentBaseline="middle" x="50.00000000000002" y={-18} className="recharts-text recharts-pie-label-text" textAnchor="start" fill="#000000">
                                                                                                                    <tspan x="50.00000000000002" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="49.999999999999986" y={118} className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                    <tspan x="49.999999999999986" dy="0em" />
                                                                                                                </text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper recharts-tooltip-wrapper-right recharts-tooltip-wrapper-bottom" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0, transform: 'translate(10px, 10px)' }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChart percentage={manager?.overall?.home?.drawPercentage || 0} type="draw" />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="team-statistics">
                                                                    <div className="row g-3">
                                                                        <div className="col-12">
                                                                            <div className="inner_wrapper_card">


                                                                                <div className="flex-content">

                                                                                    {manager?.overall?.mvp.map((data, index) =>
                                                                                    (
                                                                                        <div className="common-info-detail" key={index}>

                                                                                            <div>
                                                                                                <h2 class="h4">{data.type}</h2>
                                                                                                <h6 class="mb-0">{data.name}</h6>
                                                                                            </div>
                                                                                            <p className="mb-0">{data?.count}</p>
                                                                                        </div>
                                                                                    )
                                                                                    )}
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-attack" role="tabpanel" aria-labelledby="pills-attack-tab" tabIndex={0}>
                                                    <div className="tab-pane fade show active" id="pills-overall" role="tabpanel" aria-labelledby="pills-overall-tab" tabIndex={0}>
                                                        <div className="wrapper-card">
                                                            {/* <div className="common-sub-heading">
                                                                <h2>Match List</h2>
                                                            </div> */}
                                                            <div className="row g-3">
                                                                <div className="col-xl-6">
                                                                    <div className="team-statistics">
                                                                        <div className="row g-3">
                                                                            <div className="col-12">
                                                                                <div className="inner_wrapper_card">
                                                                                    <div className="common_pie_style_layout layout-attack">
                                                                                        <div className="common_pie_style_item">
                                                                                            <h3>Goals</h3>
                                                                                            <p>{manager?.overall?.attack?.goalsCount}</p>
                                                                                            {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                                <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                    <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                        <title />
                                                                                                        <desc />
                                                                                                        <defs>
                                                                                                            <clipPath id="recharts46-clip">
                                                                                                                <rect x={5} y={5} height={90} width={90} />
                                                                                                            </clipPath>
                                                                                                        </defs>
                                                                                                        <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                            <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                <path cx={50} cy={50} name="Filled" stroke="#fff" fill="#0086FF" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    21.786307889961286,88.83281572999746
  L 33.542012935810746,72.65247584249852
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                            </g>
                                                                                                            <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 21.786307889961286,88.83281572999746
    A 48,48,0,
    0,1,
    50,2
  L 50,22
      A 28,28,0,
      0,0,
      33.542012935810746,72.65247584249852 Z" role="img" />
                                                                                                            </g>
                                                                                                            <g className="recharts-layer recharts-pie-labels">
                                                                                                                <g className="recharts-layer">
                                                                                                                    <text x="86.14014761921584" y="61.74264578624801" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="10px">60%</text>
                                                                                                                </g>
                                                                                                                <g className="recharts-layer">
                                                                                                                    <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="-14.671843108070433" y="28.98684438250357" className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                        <tspan x="-14.671843108070433" dy="0em" />
                                                                                                                    </text>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </svg>
                                                                                                    <div tabIndex={-1} className="recharts-tooltip-wrapper" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0 }}>
                                                                                                        <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                            <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div> */}
                                                                                            <CustomPieChartFive percentage={manager?.attack?.goalsPercentage || 0} type="goals" />
                                                                                        </div>
                                                                                        <div className="home-away">
                                                                                            <div className="common_pie_style_item">
                                                                                                <h3>Home</h3>
                                                                                                <p>{manager?.overall?.attack?.homeCount}</p>
                                                                                                {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                                    <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                        <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                            <title />
                                                                                                            <desc />
                                                                                                            <defs>
                                                                                                                <clipPath id="recharts50-clip">
                                                                                                                    <rect x={5} y={5} height={90} width={90} />
                                                                                                                </clipPath>
                                                                                                            </defs>
                                                                                                            <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                                <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                    <path cx={50} cy={50} name="Filled" stroke="#fff" fill="#FD3D59" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    1,1,
    8.425754981852933,73.9912932367364
  L 25.74835707274754,63.9949210547629
      A 28,28,0,
      1,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                                </g>
                                                                                                                <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                    <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 8.425754981852933,73.9912932367364
    A 48,48,0,
    0,1,
    50.00000000000002,2
  L 50.000000000000014,22
      A 28,28,0,
      0,0,
      25.74835707274754,63.9949210547629 Z" role="img" />
                                                                                                                </g>
                                                                                                                <g className="recharts-layer recharts-pie-labels">
                                                                                                                    <g className="recharts-layer">
                                                                                                                        <text x="82.90697548802143" y="69.00344611460662" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="10px">67%</text>
                                                                                                                    </g>
                                                                                                                    <g className="recharts-layer">
                                                                                                                        <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="-8.886166662775182" y="15.993833268598706" className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                            <tspan x="-8.886166662775182" dy="0em" />
                                                                                                                        </text>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                        <div tabIndex={-1} className="recharts-tooltip-wrapper" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0 }}>
                                                                                                            <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                                <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div> */}
                                                                                                <CustomPieChartFive percentage={manager?.attack?.homePercentage || 0} type="home" />
                                                                                            </div>
                                                                                            <div className="common_pie_style_item">
                                                                                                <h3>Away</h3>
                                                                                                <p>{manager?.overall?.attack?.awayCount}</p>
                                                                                                {/* <div className="recharts-responsive-container" style={{ width: 100, height: 100, minWidth: 0 }}>
                                                                                                    <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 100, maxWidth: 100 }}>
                                                                                                        <svg cx="50%" cy="50%" className="recharts-surface" width={100} height={100} viewBox="0 0 100 100" style={{ width: '100%', height: '100%' }}>
                                                                                                            <title />
                                                                                                            <desc />
                                                                                                            <defs>
                                                                                                                <clipPath id="recharts54-clip">
                                                                                                                    <rect x={5} y={5} height={90} width={90} />
                                                                                                                </clipPath>
                                                                                                            </defs>
                                                                                                            <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                                <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                    <path cx={50} cy={50} name="Filled" stroke="#fff" fill="#000000" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 50.000000000000014,2
    A 48,48,0,
    0,1,
    91.57424501814705,73.99129323673642
  L 74.25164292725245,63.99492105476292
      A 28,28,0,
      0,0,
      50.00000000000001,22 Z" role="img" />
                                                                                                                </g>
                                                                                                                <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                    <path cx={50} cy={50} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 91.57424501814705,73.99129323673642
    A 48,48,0,
    1,1,
    50.00000000000002,2
  L 50.000000000000014,22
      A 28,28,0,
      1,0,
      74.25164292725245,63.99492105476292 Z" role="img" />
                                                                                                                </g>
                                                                                                                <g className="recharts-layer recharts-pie-labels">
                                                                                                                    <g className="recharts-layer">
                                                                                                                        <text x="82.90697548802146" y="30.996553885393432" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="10px">33%</text>
                                                                                                                    </g>
                                                                                                                    <g className="recharts-layer">
                                                                                                                        <text cx={50} cy={50} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="-8.886166662775224" y="84.00616673140124" className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                            <tspan x="-8.886166662775224" dy="0em" />
                                                                                                                        </text>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </g>
                                                                                                        </svg>
                                                                                                        <div tabIndex={-1} className="recharts-tooltip-wrapper" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0 }}>
                                                                                                            <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                                <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div> */}
                                                                                                <CustomPieChartFive percentage={manager?.attack?.awayPercentage || 0} type="away" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <h4>Average Goals</h4>
                                                                                    <div className="common_style_layout">
                                                                                        <div className="common_style_item">
                                                                                            <h3>Per Game</h3>
                                                                                            <div className="common_style_value">
                                                                                                <p>{manager?.attack?.averageGoalPerGame}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="common_style_item">
                                                                                            <h3>Home</h3>
                                                                                            <p>{manager?.attack?.averageGoalHome}</p>
                                                                                        </div>
                                                                                        <div className="common_style_item">
                                                                                            <h3>Away</h3>
                                                                                            <p>{manager?.attack?.averageGoalAway}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6 mt-3">
                                                                                            <div className="common_style_layout attack-assist-layout">
                                                                                                <div className="common_style_item">
                                                                                                    <h3>Assists</h3>
                                                                                                    <div className="common_style_value">
                                                                                                        <p>{manager?.attack?.assist}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6 mt-3">
                                                                                            <div className="common_style_layout attack-assist-layout">
                                                                                                <div className="common_style_item">
                                                                                                    <h3>Avg Assists Per Game</h3>
                                                                                                    <div className="common_style_value">
                                                                                                        <p>{manager?.attack?.averageAssist}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="team-statistics">
                                                                        <div className="row g-3">
                                                                            <div className="col-12">
                                                                                <div className="inner_wrapper_card">
                                                                                    <div className="row g-3 align-items-center">
                                                                                        <div className="col-12">
                                                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                <h2>Where Scored</h2>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="where-scored d-flex justify-content-evenly">
                                                                                                <div className="common_pie_style_item">
                                                                                                    <h3>Inside the Box</h3>
                                                                                                    <p>{manager?.attack?.whereScored?.insideTheBoxCount}</p>
                                                                                                    {/* <div className="recharts-responsive-container" style={{ width: 200, height: 200, minWidth: 0 }}>
                                                                                                        <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 200, maxWidth: 200 }}>
                                                                                                            <svg cx="50%" cy="50%" className="recharts-surface" width={200} height={200} viewBox="0 0 200 200" style={{ width: '100%', height: '100%' }}>
                                                                                                                <title />
                                                                                                                <desc />
                                                                                                                <defs>
                                                                                                                    <clipPath id="recharts58-clip">
                                                                                                                        <rect x={5} y={5} height={190} width={190} />
                                                                                                                    </clipPath>
                                                                                                                </defs>
                                                                                                                <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                                    <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                        <path cx={100} cy={100} name="Filled" stroke="#fff" fill="#0086FF" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 100.00000000000003,0
    A 100,100,0,
    1,1,
    13.386989545526944,149.9818609098675
  L 39.37089268186886,134.98730263690726
      A 70,70,0,
      1,0,
      100.00000000000003,30 Z" role="img" />
                                                                                                                    </g>
                                                                                                                    <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                        <path cx={100} cy={100} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 13.386989545526944,149.9818609098675
    A 100,100,0,
    0,1,
    100.00000000000006,0
  L 100.00000000000003,30
      A 70,70,0,
      0,0,
      39.37089268186886,134.98730263690726 Z" role="img" />
                                                                                                                    </g>
                                                                                                                    <g className="recharts-layer recharts-pie-labels">
                                                                                                                        <g className="recharts-layer">
                                                                                                                            <text x="173.60770832846896" y="142.50770841425162" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="10px">67%</text>
                                                                                                                        </g>
                                                                                                                        <g className="recharts-layer">
                                                                                                                            <text cx={100} cy={100} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="-3.916764699015033" y="39.98911753282124" className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                                <tspan x="-3.916764699015033" dy="0em" />
                                                                                                                            </text>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <div tabIndex={-1} className="recharts-tooltip-wrapper" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0 }}>
                                                                                                                <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                                    <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                    <CustomPieChartThree percentage={manager?.attack?.whereScored?.insideTheBoxPercentage || 0} type="inside" />
                                                                                                </div>
                                                                                                <div className="common_pie_style_item">
                                                                                                    <h3>Outside the Box</h3>
                                                                                                    <p>{manager?.attack?.whereScored?.outsideTheBoxPercentage}</p>
                                                                                                    {/* <div className="recharts-responsive-container" style={{ width: 200, height: 200, minWidth: 0 }}>
                                                                                                        <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 200, maxWidth: 200 }}>
                                                                                                            <svg cx="50%" cy="50%" className="recharts-surface" width={200} height={200} viewBox="0 0 200 200" style={{ width: '100%', height: '100%' }}>
                                                                                                                <title />
                                                                                                                <desc />
                                                                                                                <defs>
                                                                                                                    <clipPath id="recharts62-clip">
                                                                                                                        <rect x={5} y={5} height={190} width={190} />
                                                                                                                    </clipPath>
                                                                                                                </defs>
                                                                                                                <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                                    <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                        <path cx={100} cy={100} name="Filled" stroke="#fff" fill="#FD3D59" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 100.00000000000003,0
    A 100,100,0,
    0,1,
    186.613010454473,149.98186090986758
  L 160.6291073181311,134.98730263690732
      A 70,70,0,
      0,0,
      100.00000000000003,30 Z" role="img" />
                                                                                                                    </g>
                                                                                                                    <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                                        <path cx={100} cy={100} name="Remaining" stroke="#fff" fill="#E6E6EC" fillOpacity={1} tabIndex={-1} className="recharts-sector" d="M 186.613010454473,149.98186090986758
    A 100,100,0,
    1,1,
    100.00000000000006,0
  L 100.00000000000003,30
      A 70,70,0,
      1,0,
      160.6291073181311,134.98730263690732 Z" role="img" />
                                                                                                                    </g>
                                                                                                                    <g className="recharts-layer recharts-pie-labels">
                                                                                                                        <g className="recharts-layer">
                                                                                                                            <text x="173.60770832846902" y="57.49229158574847" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="10px">33%</text>
                                                                                                                        </g>
                                                                                                                        <g className="recharts-layer">
                                                                                                                            <text cx={100} cy={100} stroke="none" name="Remaining" fillOpacity={1} alignmentBaseline="middle" x="-3.91676469901509" y="160.01088246717865" className="recharts-text recharts-pie-label-text" textAnchor="end" fill="#E6E6EC">
                                                                                                                                <tspan x="-3.91676469901509" dy="0em" />
                                                                                                                            </text>
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>
                                                                                                            <div tabIndex={-1} className="recharts-tooltip-wrapper" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0 }}>
                                                                                                                <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                                    <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div> */}
                                                                                                    <CustomPieChartThree percentage={manager?.attack?.whereScored?.insideTheBoxPercentage || 0} type="outside" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="team-statistics">
                                                                        <div className="row g-3">
                                                                            <div className="col-12">
                                                                                <div className="inner_wrapper_card">
                                                                                    <div className="row g-3 align-items-center">
                                                                                        <div className="col-12">
                                                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                <h2>When Scored</h2>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="common_style_layout">
                                                                                                <div className="common_style_item">
                                                                                                    <h3>1st Half</h3>
                                                                                                    <p>{manager?.attack?.whenScored?.firstHalfCount}</p>
                                                                                                    <p>{manager?.attack?.whenScored?.firstHalfPercentage}</p>
                                                                                                </div>
                                                                                                <div className="common_style_item">
                                                                                                    <h3>2nd Half</h3>
                                                                                                    <p>{manager?.attack?.whenScored?.secondHalfCount}</p>
                                                                                                    <p>{manager?.attack?.whenScored?.secondHalfPercentage}</p>
                                                                                                </div>
                                                                                                <div className="common_style_item">
                                                                                                    <h3>Extra Time</h3>
                                                                                                    <p>{manager?.attack?.whenScored?.extraTimeCount}</p>
                                                                                                    <p>{manager?.attack?.whenScored?.extraTimePercentage}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <div className="team-statistics">
                                                                        <div className="bg-white-statistics">
                                                                            <div className="row g-3 align-items-center">
                                                                                <div className="col-12">
                                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                                        <h2>How Scored</h2>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-5">
                                                                                    <div className="row">
                                                                                        <div className="col-sm-12">
                                                                                            <div className="how-score-blk">
                                                                                                <div className="how-score-icon">
                                                                                                    <img src="/assets/images/right-foot.png" alt="Foot" />
                                                                                                </div>
                                                                                                <div className="how-score-info">
                                                                                                    <h3>{manager?.attack?.howScored
                                                                                                        ?.rightFootCount}</h3>
                                                                                                    <p>Right foot</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-12">
                                                                                            <div className="how-score-blk">
                                                                                                <div className="how-score-icon">
                                                                                                    <img src="/assets/images/left-foot.png" alt="Foot" />
                                                                                                </div>
                                                                                                <div className="how-score-info">
                                                                                                    <h3>{manager?.attack?.howScored
                                                                                                        ?.leftFootCount}</h3>
                                                                                                    <p>Left foot</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-12">
                                                                                            <div className="how-score-blk">
                                                                                                <div className="how-score-icon">
                                                                                                    <img src="/assets/images/head.png" alt="Head" />
                                                                                                </div>
                                                                                                <div className="how-score-info">
                                                                                                    <h3>{manager?.attack?.howScored
                                                                                                        ?.headedCount}</h3>
                                                                                                    <p>Head</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-12">
                                                                                            <div className="how-score-blk border-bottom-0">
                                                                                                <div className="how-score-icon">
                                                                                                    <img src="/assets/images/other.png" alt="By Other" />
                                                                                                </div>
                                                                                                <div className="how-score-info">
                                                                                                    <h3>{manager?.attack?.howScored
                                                                                                        ?.otherCount}</h3>
                                                                                                    <p>Other</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-7">
                                                                                    <div className="how_pie_graph_blk">
                                                                                        {/* <div className="recharts-responsive-container" style={{ width: 200, height: 200, minWidth: 0 }}>
                                                                                            <div className="recharts-wrapper" style={{ position: 'relative', cursor: 'default', width: '100%', height: '100%', maxHeight: 200, maxWidth: 200 }}>
                                                                                                <svg cx="50%" cy="50%" className="recharts-surface" width={200} height={200} viewBox="0 0 200 200" style={{ width: '100%', height: '100%' }}>
                                                                                                    <title />
                                                                                                    <desc />
                                                                                                    <defs>
                                                                                                        <clipPath id="recharts38-clip">
                                                                                                            <rect x={5} y={5} height={190} width={190} />
                                                                                                        </clipPath>
                                                                                                    </defs>
                                                                                                    <g className="recharts-layer recharts-pie" tabIndex={0}>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={100} cy={100} name="Right" stroke="#fff" fill="#0086FF" tabIndex={-1} className="recharts-sector" d="M 100.00000000000003,0
    A 100,100,0,
    0,1,
    186.613010454473,149.98186090986758
  L 160.6291073181311,134.98730263690732
      A 70,70,0,
      0,0,
      100.00000000000003,30 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={100} cy={100} name="Left" stroke="#fff" fill="#FD3D59" tabIndex={-1} className="recharts-sector" d="M 186.613010454473,149.98186090986758
    A 100,100,0,
    0,1,
    13.418411169592488,150.03627159973308
  L 39.39288781871475,135.02539011981315
      A 70,70,0,
      0,0,
      160.6291073181311,134.98730263690732 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={100} cy={100} name="Headed" stroke="#fff" fill="#000000" tabIndex={-1} className="recharts-sector" d="M 13.418411169592488,150.03627159973308
    A 100,100,0,
    0,1,
    99.93716815106237,0.000019739208156011046
  L 99.95601770574366,30.000013817445705
      A 70,70,0,
      0,0,
      39.39288781871475,135.02539011981315 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-sector" tabIndex={-1}>
                                                                                                            <path cx={100} cy={100} name="Other" stroke="#fff" fill="#E6E6EC" tabIndex={-1} className="recharts-sector" d="M 99.93716815106237,0.000019739208156011046
    A 100,100,0,
    0,1,
    100.00000000000003,0
  L 100.00000000000001,30
      A 70,70,0,
      0,0,
      99.95601770574366,30.000013817445705 Z" role="img" />
                                                                                                        </g>
                                                                                                        <g className="recharts-layer recharts-pie-labels">
                                                                                                            <g className="recharts-layer">
                                                                                                                <text x="173.60770832846902" y="57.49229158574847" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="12px">33%</text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text x="100.02670353711629" y="184.99999580541817" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="12px">33%</text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text x="26.365597821972074" y="57.53854906053686" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="12px">33%</text>
                                                                                                            </g>
                                                                                                            <g className="recharts-layer">
                                                                                                                <text x="99.97329646288374" y="15.000004194581834" fill="white" textAnchor="middle" dominantBaseline="central" fontSize="12px">0%</text>
                                                                                                            </g>
                                                                                                        </g>
                                                                                                    </g>
                                                                                                </svg>
                                                                                                <div tabIndex={-1} className="recharts-tooltip-wrapper" style={{ visibility: 'hidden', pointerEvents: 'none', position: 'absolute', top: 0, left: 0 }}>
                                                                                                    <div className="recharts-default-tooltip" style={{ margin: 0, padding: 10, backgroundColor: 'rgb(255, 255, 255)', border: '1px solid rgb(204, 204, 204)', whiteSpace: 'nowrap' }}>
                                                                                                        <p className="recharts-tooltip-label" style={{ margin: 0 }} />
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div> */}
                                                                                        <CustomPieChartTwo right={manager?.attack?.howScored?.rightFootPercentage || 0} left={manager?.attack?.howScored?.leftFootPercentage || 0} headed={manager?.attack?.howScored?.headedPercentage || 0} other={manager?.attack?.howScored?.otherPercentage || 0} />
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="team-statistics">
                                                                        <div className="bg-white-statistics">
                                                                            <div className="row g-3 align-items-center">
                                                                                <div className="col-12">
                                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                                        <h2>Play When Scored</h2>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="how_pie_graph_blk">
                                                                                        <CustomPieChartFour openPlay={manager?.attack?.playWhenScored?.openPlayPercentage || 0} penalty={manager?.attack?.playWhenScored?.penaltyPercentage || 0} direct={manager?.attack?.playWhenScored?.directFreeKickPercentage || 0} indirect={manager?.attack?.playWhenScored?.indirectFreeKickPercentage || 0} corner={manager?.attack?.playWhenScored?.cornerPercentage || 0} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(52, 75, 253)' }} />
                                                                                                <h3>Open Play</h3>
                                                                                            </div>
                                                                                            <p>{manager?.attack?.playWhenScored?.openPlayCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(253, 61, 89)' }} />
                                                                                                <h3>Penalty</h3>
                                                                                            </div>
                                                                                            <p>{manager?.attack?.playWhenScored?.penaltyCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(0, 0, 0)' }} />
                                                                                                <h3>Direct Free Kick</h3>
                                                                                            </div>
                                                                                            <p>{manager?.attack?.playWhenScored?.directFreeKickCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(239, 239, 239)' }} />
                                                                                                <h3>Indirect Free Kick</h3>
                                                                                            </div>
                                                                                            <p>{manager?.attack?.playWhenScored?.indirectFreeKickCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(226, 226, 226)' }} />
                                                                                                <h3>Corner</h3>
                                                                                            </div>
                                                                                            <p>{manager?.attack?.playWhenScored?.cornerCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                                <div className="tab-pane fade" id="pills-defense" role="tabpanel" aria-labelledby="pills-defense-tab" tabIndex={0}>
                                                    <div className="tab-pane fade show active" id="pills-overall" role="tabpanel" aria-labelledby="pills-overall-tab" tabIndex={0}>
                                                        <div className="wrapper-card">
                                                            {/* <div className="common-sub-heading">
                                                                <h2>Match List</h2>
                                                            </div> */}
                                                            <div className="row g-3">
                                                                <div className="col-xl-6">
                                                                    <div className="team-statistics">
                                                                        <div className="row g-3">
                                                                            <div className="col-12">
                                                                                <div className="inner_wrapper_card">
                                                                                    <div className="common_pie_style_layout layout-attack">
                                                                                        <div className="common_pie_style_item">
                                                                                            <h3>Clean Sheets</h3>
                                                                                            <p>{manager?.defence?.overall?.cleanSheetsCount}</p>
                                                                                            <CustomPieChartFive percentage={manager?.defence?.cleanSheetsPercentage || 0} type="goals" />
                                                                                        </div>
                                                                                        <div className="home-away">
                                                                                            <div className="common_pie_style_item">
                                                                                                <h3>Home</h3>
                                                                                                <p>{manager?.defence?.overall?.homeCount}</p>
                                                                                                <CustomPieChartFive percentage={manager?.defence?.homePercentage || 0} type="home" />
                                                                                            </div>
                                                                                            <div className="common_pie_style_item">
                                                                                                <h3>Away</h3>
                                                                                                <p>{manager?.defence?.overall?.awayCount}</p>
                                                                                                <CustomPieChartFive percentage={manager?.defence?.awayPercentage || 0} type="away" />
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="row">
                                                                                        <div className="col-sm-6 mt-3 mb-3">
                                                                                            <div className="common_style_layout attack-assist-layout">
                                                                                                <div className="common_style_item">
                                                                                                    <h3>Penalty Saves</h3>
                                                                                                    <div className="common_style_value">
                                                                                                        <p>{manager?.defence?.penaltySavesCount}</p>
                                                                                                        <div className="v-divider" />
                                                                                                        <p>{manager?.defence?.penaltySavesPercentage}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-sm-6 mt-3 mb-3">
                                                                                            <div className="common_style_layout attack-assist-layout">
                                                                                                <div className="common_style_item">
                                                                                                    <h3>Penalty Conceded</h3>
                                                                                                    <div className="common_style_value">
                                                                                                        <p>{manager?.defence?.penaltyConceded}</p>
                                                                                                        <div className="v-divider" />
                                                                                                        <p>{manager?.defence?.penaltyConcededPercentage}</p>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="common_style_layout mb-3">
                                                                                        <div className="common_style_item">
                                                                                            <h3>Goals Conceded</h3>
                                                                                            <div className="common_style_value">
                                                                                                <p>{manager?.defence?.goalConcededCount}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="common_style_item">
                                                                                            <h3>Home</h3>
                                                                                            <p>{manager?.defence?.goalConcededHomeCount}</p>
                                                                                        </div>
                                                                                        <div className="common_style_item">
                                                                                            <h3>Away</h3>
                                                                                            <p>{manager?.defence?.goalConcededAwayCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="common_style_layout">
                                                                                        <div className="common_style_item">
                                                                                            <h3>Average Conceded P/G</h3>
                                                                                            <div className="common_style_value">
                                                                                                <p>{manager?.defence?.averageConcededPercentage}</p>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="common_style_item">
                                                                                            <h3>Home</h3>
                                                                                            <p>{manager?.defence?.averageGoalConcededAwayCount}</p>
                                                                                        </div>
                                                                                        <div className="common_style_item">
                                                                                            <h3>Away</h3>
                                                                                            <p>{manager?.defence?.averageGoalConcededHomeCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="team-statistics">
                                                                        <div className="bg-white-statistics">
                                                                            <div className="row g-3 align-items-center">
                                                                                <div className="col-12">
                                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                                        <h2>How Conceded</h2>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="how_pie_graph_blk">
                                                                                        <CustomPieChartFour openPlay={manager?.defence?.howConceded?.openPlayPercentage || 0} penalty={manager?.defence?.howConceded?.penaltyPercentage || 0} direct={manager?.defence?.howConceded?.directFreeKickPercentage || 0} indirect={manager?.defence?.howConceded?.indirectFreeKickPercentage || 0} corner={manager?.defence?.howConceded?.cornerPercentage || 0} />
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-lg-6">
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(52, 75, 253)' }} />
                                                                                                <h3>Open Play</h3>
                                                                                            </div>
                                                                                            <p>{manager?.defence?.howConceded?.openPlayCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(253, 61, 89)' }} />
                                                                                                <h3>Penalty</h3>
                                                                                            </div>
                                                                                            <p>{manager?.defence?.howConceded?.penaltyCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(0, 0, 0)' }} />
                                                                                                <h3>Direct Free Kick</h3>
                                                                                            </div>
                                                                                            <p>{manager?.defence?.howConceded?.directFreeKickCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(239, 239, 239)' }} />
                                                                                                <h3>Indirect Free Kick</h3>
                                                                                            </div>
                                                                                            <p>{manager?.defence?.howConceded?.indirectFreeKickCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                    <div className="conceded-score-graph-detail">
                                                                                        <div className="conceded-score-blk">
                                                                                            <div className="conceded-score-info">
                                                                                                <span className="conceded-label-icon" style={{ backgroundColor: 'rgb(226, 226, 226)' }} />
                                                                                                <h3>Corner</h3>
                                                                                            </div>
                                                                                            <p>{manager?.defence?.howConceded?.cornerCount}</p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                                <div className="col-xl-6">
                                                                    <div className="team-statistics">
                                                                        <div className="row g-3">
                                                                            <div className="col-12">
                                                                                <div className="inner_wrapper_card">
                                                                                    <div className="row g-3 align-items-center">
                                                                                        <div className="col-12">
                                                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                <h2>When Conceded</h2>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="common_style_layout">
                                                                                                <div className="common_style_item">
                                                                                                    <h3>1st Half</h3>
                                                                                                    <p>{manager?.defence?.whenConceded?.firstHalfCount}</p>
                                                                                                    <p>{manager?.defence?.whenConceded?.firstHalfPercentage}</p>
                                                                                                </div>
                                                                                                <div className="common_style_item">
                                                                                                    <h3>2nd Half</h3>
                                                                                                    <p>{manager?.defence?.whenConceded?.secondHalfCount}</p>
                                                                                                    <p>{manager?.defence?.whenConceded?.secondHalfPercentage}</p>
                                                                                                </div>
                                                                                                <div className="common_style_item">
                                                                                                    <h3>Extra Time</h3>
                                                                                                    <p>{manager?.defence?.whenConceded?.extraTimeCount}</p>
                                                                                                    <p>{manager?.defence?.whenConceded?.extraTimePercentage}</p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="team-statistics">
                                                                        <div className="row g-3">
                                                                            <div className="col-12">
                                                                                <div className="inner_wrapper_card">
                                                                                    <div className="row g-3 align-items-center">
                                                                                        <div className="col-12">
                                                                                            <div className="top-heading d-flex justify-content-between mb-2">
                                                                                                <h2>Where Conceded</h2>
                                                                                            </div>
                                                                                        </div>
                                                                                        <div className="col-12">
                                                                                            <div className="where-scored d-flex justify-content-evenly">
                                                                                                <div className="common_pie_style_item">
                                                                                                    <h3>Inside the Box</h3>
                                                                                                    <p>{manager?.defence?.whereConceded?.insideTheBoxCount}</p>
                                                                                                    <CustomPieChartThree percentage={manager?.defence?.whereConceded?.insideTheBoxPercentage || 0} type="inside" />
                                                                                                </div>
                                                                                                <div className="common_pie_style_item">
                                                                                                    <h3>Outside the Box</h3>
                                                                                                    <p>{manager?.defence?.whereConceded?.insideTheBoxCount}</p>
                                                                                                    <CustomPieChartThree percentage={manager?.defence?.whereConceded?.outsideTheBoxPercentage || 0} type="outside" />
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div className="team-statistics">
                                                                        <div className="bg-white-statistics">
                                                                            <div className="row g-3 align-items-center">
                                                                                <div className="col-12">
                                                                                    <div className="top-heading d-flex justify-content-between mb-2">
                                                                                        <h2>Where Beaten</h2>
                                                                                    </div>
                                                                                </div>
                                                                                <div className="col-12">
                                                                                    <div className="ground_design_wrapper">
                                                                                        <div className="box_wrapper">
                                                                                            <div className="box_inner_wrapper">
                                                                                                <div className="row row-cols-3 g-1">
                                                                                                    <div className="col">
                                                                                                        <div className="row gy-1">
                                                                                                            <div className="col-12">
                                                                                                                <div className="design-box bg-primary">{`${Math.round(manager?.defence?.whenConceded?.whereBeaten?.[0])}% `}</div>
                                                                                                            </div>
                                                                                                            <div className="col-12">
                                                                                                                <div className="design-box bg-primary">{`${Math.round(manager?.defence?.whenConceded?.whereBeaten?.[3])}% `}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <div className="row gy-1">
                                                                                                            <div className="col-12">
                                                                                                                <div className="design-box bg-custom-secondary">{`${Math.round(manager?.defence?.whenConceded?.whereBeaten?.[1])}% `}</div>
                                                                                                            </div>
                                                                                                            <div className="col-12">
                                                                                                                <div className="design-box bg-custom-secondary">{`${Math.round(manager?.defence?.whenConceded?.whereBeaten?.[4])}% `}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div className="col">
                                                                                                        <div className="row gy-1">
                                                                                                            <div className="col-12">
                                                                                                                <div className="design-box bg-black">{`${Math.round(manager?.defence?.whenConceded?.whereBeaten?.[2])}% `}</div>
                                                                                                            </div>
                                                                                                            <div className="col-12">
                                                                                                                <div className="design-box bg-black">{`${Math.round(manager?.defence?.whenConceded?.whereBeaten?.[5])}% `}</div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                    <div className="tab-pane fade" id="pills-rankings" role="tabpanel" aria-labelledby="pills-rankings-tab" tabIndex={0}>222</div>
                                    <div className="tab-pane fade" id="pills-players" role="tabpanel" aria-labelledby="pills-players-tab" tabIndex={0}>333</div>
                                </div>
                            </div>
                        </div>
                    </div>
                </section>
            </div>
        </div>

    )
}

export default ManagerDetail