import axiosInstance from "./axiosInstance";

// const backendUrl = process.env.REACT_APP_BACKEND_URL;
const loginApi = process.env.REACT_APP_LOGIN_API;


export const login = async (credentials) => {
    try {
        const response = await axiosInstance.post(loginApi, credentials);
        return response.data;
    } catch (error) {
        console.error('Eroor while login:', error);
        throw error;
    }
};
